import { Col, Layout, Row } from 'antd'
import Logo from './Logo'
import Navigation from './Navigation'
import { GlobalSearch } from '../Matters/GlobalSearch'
import { matchesRole, ROLE } from '@skribe/web/core'
import { ChatPanelSwitch } from './ChatPanelSwitch'
import { LatestNews } from '../components'

export const Header: React.FC<{ userRole?: ROLE }> = ({ userRole }) => {
  return (
    <Layout.Header className="header">
      <Row justify="space-between" align="middle">
        <Col>
          <Logo />
        </Col>
        <Col>
          {matchesRole(userRole, [ROLE.USER, ROLE.LIAISON]) && <GlobalSearch />}
        </Col>
        <Col>
          <Row align={'middle'}>
            <Col>
              <LatestNews />
            </Col>
            <Col>
              {matchesRole(userRole, [ROLE.USER]) && <ChatPanelSwitch />}
            </Col>
            <Col>
              <Navigation />
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout.Header>
  )
}
