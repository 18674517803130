import { useContext } from 'react'
import { PlaylistsContext } from './PlaylistsContext'

export const usePlaylistsContext = () => {
  const ctx = useContext(PlaylistsContext)
  if (!ctx)
    throw Error('Trying to use context outside of TranscriptPageContext')
  const enableMultiselect = () => {
    ctx.setMultiSelectClips([])
  }

  const disableMultiselect = () => {
    ctx.setMultiSelectClips(undefined)
  }
  const isMultiSelectEnabled = ctx.multiSelectClips !== undefined

  const toggleMultiselect = isMultiSelectEnabled
    ? disableMultiselect
    : enableMultiselect

  const isClipSelected = (clipId: string) =>
    ctx.multiSelectClips?.includes(clipId)

  const toggleClipSelection = (clipId: string) => {
    const newPlaylist = toggleClipOnPlaylist(clipId, ctx.multiSelectClips)
    ctx.setMultiSelectClips(newPlaylist)
  }

  return {
    ...ctx,
    disableMultiselect,
    toggleMultiselect,
    isMultiSelectEnabled,
    isClipSelected,
    toggleClipSelection,
  }
}

export function toggleClipOnPlaylist(
  clipId: string,
  playlist: string[] | undefined,
): string[] {
  if (!playlist || playlist.length === 0) return [clipId]

  return playlist.includes(clipId)
    ? playlist.filter(id => id !== clipId)
    : [...playlist, clipId]
}
