import { Monologue, Nullable, unique } from '@skribe/web/core'

export type Speaker = {
  name: string
  index: number
}

export function mapToSpeakers(speakers: string[]): Speaker[] {
  return speakers
    .map((name, index) => ({ name, index }))
    .filter(speaker => speaker.name)
}

export function getSpeakersV1(dialogue: {
  speakers: string[] | null | undefined
  monologues: Monologue[]
}): string[] {
  if (dialogue.speakers?.length) {
    return dialogue.speakers
  }

  const uniqueSpeakers = getUniqueSpeakers(dialogue)
  const lastSpeakerIndex = getLastSpeakerIndex(uniqueSpeakers)

  return nameSpeakers(lastSpeakerIndex, uniqueSpeakers)
}
export function getSpeakers(dialogue: {
  speakers?: Nullable<Speaker[]>
  monologues: Monologue[]
}): Speaker[] {
  const { speakers, monologues } = dialogue
  if (speakers) return speakers

  return unique(monologues, 'speaker').map<Speaker>(m => ({
    name: m.speaker_info?.display_name ?? `Speaker ${m.speaker + 1}`,
    index: m.speaker,
  }))
}

function getUniqueSpeakers(dialogue: {
  speakers: string[] | null | undefined
  monologues: Monologue[]
}) {
  return new Set([...dialogue.monologues.map(m => m.speaker)])
}

function getLastSpeakerIndex(uniqueSpeakers: Set<number>) {
  return Math.max(...Array.from(uniqueSpeakers.values()), -1)
}

function nameSpeakers(
  lastSpeakerIndex: number,
  uniqueSpeakers: Set<number>,
): string[] {
  let speakers = []
  let speakerNumber = 1
  for (let i = 0; i <= lastSpeakerIndex; i++) {
    if (uniqueSpeakers.has(i)) {
      speakers[i] = `Speaker ${speakerNumber}`
      speakerNumber++
    } else {
      speakers[i] = ''
    }
  }
  return speakers
}
