import { FC } from 'react'
import ReactDragListView from 'react-drag-listview'
import { List, Skeleton } from 'antd'
import { Clip, Playlist } from '@skribe/web/core'
import { useOnCopyNotification } from '../../Notifications/onCopy'
import { useClipList } from './useClipList'
import './ClipList.css'
import { ClipItem } from './ClipListItem/ClipItem'
import { ManualClipCreator } from './ManualClipCreator'
import { ClipListPlaceholder } from './ClipListPlaceholder'

const CREATE_CLIP_BUTTON = 'create'

type ClipListProps = {
  clips: Clip[]
  onUpdateName: (id: string, name: string) => void
  transcriptDuration: number
  onUpdateSequence: (clips: Clip[]) => void
  playlists?: Playlist[]
}

export const ClipList: FC<ClipListProps> = ({
  clips,
  onUpdateName,
  transcriptDuration,
  playlists,
  onUpdateSequence,
}) => {
  const { onCopy, notificationContext } = useOnCopyNotification()

  const {
    onPlay,
    selectClip,
    deleteClip,
    copyClipUrl,
    onDragEnd,
    sortedClips,
    selectedClip,
    isMediaPlaying,
    stopPlaying,
    createClipAndTrack,
  } = useClipList({ clips, onCopy, onUpdateSequence })

  const clipsWithManualClipCreator = [
    { id: CREATE_CLIP_BUTTON } as Clip,
    ...(sortedClips ?? []),
  ]
  return (
    <ReactDragListView
      nodeSelector=".ant-list-item.draggable"
      lineClassName="dragLine"
      onDragEnd={onDragEnd}
      enableScroll={false}
    >
      {notificationContext}
      <List
        data-testid={'ClipList'}
        rowKey="id"
        size="large"
        className="clip-list"
        dataSource={clipsWithManualClipCreator}
        locale={{ emptyText: <ClipListPlaceholder /> }}
        renderItem={clip =>
          clip.id === CREATE_CLIP_BUTTON ? (
            <>
              <ManualClipCreator
                onCreate={createClipAndTrack}
                duration={transcriptDuration}
              />
              {sortedClips?.length === 0 && <ClipListPlaceholder />}
            </>
          ) : (
            <ClipItem
              playlists={playlists}
              selectClip={selectClip}
              selectedClipId={selectedClip?.id}
              clip={clip}
              onCopy={() => copyClipUrl(clip)}
              onDelete={() => deleteClip(clip.id)}
              onPlay={() => onPlay(clip)}
              onPause={stopPlaying}
              editTitle={value => onUpdateName(clip.id, value)}
              isPlaying={isMediaPlaying}
              transcriptDuration={transcriptDuration}
            />
          )
        }
      />
    </ReactDragListView>
  )
}

export function SkeletonClipList() {
  return (
    <List
      data-testid={'clipListSkeleton'}
      itemLayout="vertical"
      size="large"
      dataSource={[1, 2, 3, 4]}
      renderItem={(_clipPlaceholder, index) => (
        <List.Item key={index}>
          <Skeleton
            loading
            active
            title={{ width: '50%' }}
            paragraph={{ rows: 1, width: '90%' }}
            avatar={{ size: 'small' }}
          />
        </List.Item>
      )}
    />
  )
}
