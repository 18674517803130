import { Playlist } from '@skribe/web/core'
import { playlistDb } from '@skribe/web/api'

export function deleteClipFromPlaylists(
  clipId: string,
  playlists: Playlist[] | undefined,
) {
  return applyActionToPlaylists(clipId, playlists, playlist =>
    deleteClipFromPlaylist(playlist, clipId),
  )
}

export function markPlaylistsOutOfDate(
  clipId: string,
  playlists: Playlist[] | undefined,
) {
  return applyActionToPlaylists(clipId, playlists, playlist =>
    markPlaylistOutOfDate(playlist.id),
  )
}

async function applyActionToPlaylists(
  clipId: string,
  playlists: Playlist[] | undefined,
  action: (playlist: Playlist) => Promise<void>,
) {
  if (!playlists) return

  const matchingPlaylists = playlists.filter(p => p.clipIds?.includes(clipId))

  await Promise.all(matchingPlaylists.map(action))
}

export async function markPlaylistOutOfDate(playlistId: string) {
  await playlistDb.patchPlaylist(playlistId, {
    isDownloadUrlOutOfDate: true,
  })
}

export const deleteClipFromPlaylist = async (
  playlist: Playlist,
  clipId: string,
) => {
  await playlistDb.removeClipFromPlaylist(playlist.id, clipId)
  await markPlaylistOutOfDate(playlist.id)
}
export const deleteClipsFromPlaylist = async (
  playlist: Playlist,
  deletedClipIds: string[],
) => {
  const filteredClips = (playlist.clipIds ?? []).filter(
    clipId => !deletedClipIds.includes(clipId),
  )
  if (filteredClips.length) {
    await playlistDb.patchPlaylist(playlist.id, {
      isDownloadUrlOutOfDate: true,
      clipIds: filteredClips,
    })
  }
}
