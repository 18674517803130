import { useState } from 'react'
import { clipDb, playlistDb } from '@skribe/web/api'
import { useInvalidateData } from '../../../state'
import { trackEvent } from '@skribe/web/tracking'
import { Clip, Playlist } from 'src/core/types'
import { markPlaylistsOutOfDate } from './UseMarkPlaylistAsDirty'

export function useClipItem(props: ClipItemProps) {
  const [editModeSelected, setEditModeSelected] = useState(false)
  const { refreshClips, refreshPlaylists } = useInvalidateData()
  const openEditMode = () => {
    setEditModeSelected(true)
  }

  async function editClipLength(startTime: number, endTime: number) {
    await clipDb.patchClipLength(props.clip.id, startTime, endTime)
    await markPlaylistsOutOfDate(props.clip.id, props.playlists)
    await refreshClips()
    await refreshPlaylists()
    closeEditMenu()
  }

  async function requestDownload() {
    // TODO: This is never going to happen as its caller is not checking the dirty flags
    await clipDb.postClipDownload(props.clip.id)
    await refreshClips()

    trackEvent('requestClipDownload', { clip: props.clip })
  }

  function closeEditMenu() {
    setEditModeSelected(false)
  }

  async function addToPlaylist(playlistId: string) {
    const {
      clip: { id: clipId },
      playlists,
    } = props
    const playlist = playlists?.find(pl => pl.id === playlistId)
    if (!playlist) throw new Error('No matching playlist found')

    await playlistDb.addClipsToPlaylist(playlist, [clipId])
    trackEvent('addClipToPlaylist', { playlistId, clipId })
    await refreshPlaylists()
  }

  return {
    editModeSelected,
    openEditMode,
    editClipLength,
    closeEditMenu,
    addToPlaylist,
    requestDownload,
  }
}

export type ClipItemProps = {
  onCopy: () => void
  onDelete: () => void
  onPlay: () => void
  selectClip: (clip: Clip) => void
  editTitle?: (value: any) => void
  onPause: () => void
  clip: Clip
  selectedClipId: undefined | string
  isPlaying: boolean
  transcriptDuration: number
  playlists?: Playlist[]
}
