import { colors } from '@skribe/web/core'
import { Handle } from './Handle'
import { MonologueElementComponent } from './MonologueElementComponent'
import {
  MonologueElementContainerProps,
  useMonologueElementContainer,
} from './UseMonologueElementContainer'
import { retrieveMatchingClip } from './retrieveMatchingClip'

export function MonologueElementContainer(
  props: MonologueElementContainerProps,
) {
  const {
    shouldShowStartHandle,
    shouldShowEndHandle,
    expandStart,
    shrinkStart,
    expandEnd,
    shrinkEnd,
  } = useMonologueElementContainer(props)

  const firstMatchingClip = retrieveMatchingClip(
    props.element,
    props.clips ?? [],
  )

  return (
    <>
      {shouldShowStartHandle && (
        <Handle
          leftTooltip={'Add Prior Word'}
          rightTooltip={'Cut Next Word'}
          onLeft={expandStart}
          onRight={shrinkStart}
          leftStyle={{ color: colors.green }}
          rightStyle={{ color: colors.error }}
        />
      )}
      <MonologueElementComponent
        index={props.index}
        key={props.index}
        element={props.element}
        clip={firstMatchingClip}
        onElementClick={props.onElementClick}
      />
      {shouldShowEndHandle && (
        <Handle
          leftTooltip={'Cut Prior Word'}
          rightTooltip={'Add Next Word'}
          onLeft={shrinkEnd}
          onRight={expandEnd}
          rightStyle={{ color: colors.green }}
          leftStyle={{ color: colors.error }}
        />
      )}
    </>
  )
}
