import React, { useState } from 'react'
import { AutoComplete, Input } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useFuzzySearch } from '@skribe/web/search'
import { sortByMostRecent } from '@skribe/web/core'
import { SearchItem, SearchResult, searchDb } from '@skribe/web/api'
import { trackEvent } from '@skribe/web/tracking'
import { useQuery } from '@tanstack/react-query'
import { SEARCH_QUERY_KEY } from '../state'

const emptyData: SearchResult = {
  matters: [],
  events: [],
  transcripts: [],
  clips: [],
}

const renderTitle = (title: string) => title

const renderItem = (item: Record<string, any>, type: string) => ({
  value: item.name + item.id,
  label: item.name,
  location: '/' + type + '/' + item.id,
  type: type,
})

type OptionsType = Record<string, any>[]

const renderUiOptions = (
  matters: SearchItem[],
  events: SearchItem[],
  transcripts: SearchItem[],
): OptionsType => {
  return [
    {
      label: renderTitle('Transcripts'),
      options: transcripts.map(m => renderItem(m, 'transcripts')),
    },
    {
      label: renderTitle('Events'),
      options: events.map(m => renderItem(m, 'events')),
    },
    {
      label: renderTitle('Matters'),
      options: matters.map(m => renderItem(m, 'matters')),
    },
  ]
}

function extractItem(i: any) {
  return i.item
}

function slice(items: any[]) {
  return items.slice(0, 15)
}

const searchResultToUiOptions = (r: SearchResult) =>
  renderUiOptions(
    slice(sortByMostRecent(r.matters)),
    slice(sortByMostRecent(r.events)),
    slice(sortByMostRecent(r.transcripts)),
  )

export const GlobalSearch: React.FC = () => {
  const navigate = useNavigate()
  const { data: searchableData } = useQuery({
    queryKey: [SEARCH_QUERY_KEY],
    initialData: emptyData,
    queryFn: async (): Promise<SearchResult> => {
      const data = await searchDb.fetchSearchData()
      setUiItems(searchResultToUiOptions(data))
      return data
    },
  })

  const [query, setQuery] = useState<string>()
  const [uiItems, setUiItems] = useState<OptionsType>(
    searchResultToUiOptions(emptyData),
  )

  const fuzzyOptions = { keys: ['name'], threshold: 0.3 }
  const { fuse: matterFuse } = useFuzzySearch(
    searchableData.matters,
    fuzzyOptions,
  )
  const { fuse: eventFuse } = useFuzzySearch(
    searchableData.events,
    fuzzyOptions,
  )
  const { fuse: transcriptFuse } = useFuzzySearch(
    searchableData.transcripts,
    fuzzyOptions,
  )

  const onSearch = (input: string) => {
    setQuery(input)
    setUiItems(
      renderUiOptions(
        slice(matterFuse.search(input).map(extractItem)),
        slice(eventFuse.search(input).map(extractItem)),
        slice(transcriptFuse.search(input).map(extractItem)),
      ),
    )
  }

  function onSelect(_id: any, item: Record<'location', any>) {
    setQuery('')
    setUiItems(searchResultToUiOptions(searchableData))
    trackEvent('globalSearch', { query, ...item })
    navigate(item.location)
  }

  return (
    <AutoComplete
      defaultActiveFirstOption
      style={{ width: 750 }}
      popupMatchSelectWidth
      options={uiItems}
      value={query}
      onSearch={onSearch}
      onSelect={onSelect}
    >
      <Input.Search size="large" placeholder="Search Skribe" />
    </AutoComplete>
  )
}
