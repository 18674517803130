import { Col, List, Row, Typography } from 'antd'
import { BasicErrorComponent } from '../../../components'
import { PlaylistManagerProps, usePlaylistManager } from './usePlaylistManager'
import { CreatePlaylistItem } from './CreatePlaylistItem'
import { PlaylistItem } from './PlaylistItem'

import './PlaylistManager.css'
import { colors, Playlist } from '@skribe/web/core'
import { YoutubeOutlined } from '@ant-design/icons'

const CREATE_PLAYLIST_BUTTON = 'create'

export function PlaylistManager(
  props: PlaylistManagerProps & {
    playlists: Playlist[]
    error: Error | null
  },
) {
  const {
    createPlaylist,
    requestDownload,
    deletePlaylist,
    update,
    findMatchingClips,
  } = usePlaylistManager(props)

  if (props.error)
    return <BasicErrorComponent message="Unable to load Playlists" />

  return (
    <div className="list-container">
      <List
        className="playlist-manager"
        rowKey="id"
        size="large"
        dataSource={[
          { id: CREATE_PLAYLIST_BUTTON } as Playlist,
          ...props.playlists,
        ]}
        renderItem={playlist =>
          playlist.id === CREATE_PLAYLIST_BUTTON ? (
            <>
              <CreatePlaylistItem onCreate={createPlaylist} />
              {props.playlists.length === 0 && <PlaylistsPlaceholder />}
            </>
          ) : (
            <PlaylistItem
              requestDownload={
                props.canDownloadPlaylist ? requestDownload : undefined
              }
              playlist={playlist}
              delete={deletePlaylist}
              update={update}
              clips={findMatchingClips(playlist.clipIds)}
            />
          )
        }
      />
    </div>
  )
}

export function PlaylistsPlaceholder() {
  return (
    <Col style={{ textAlign: 'center', marginTop: '20px' }}>
      <Row justify={'center'}>
        <YoutubeOutlined style={{ fontSize: '5rem', color: colors.disabled }} />
      </Row>
      <Row justify={'center'}>
        <Typography.Text strong type="secondary">
          Create a playlist to easily access and present your most relevant
          video clips in your preferred order
        </Typography.Text>
      </Row>
      <Row style={{ textAlign: 'start' }}>
        <Typography.Text type="secondary">
          <ul style={{ listStyleType: 'disc' }}>
            <li>Click the 'Create New Playlist' icon to get started."</li>
          </ul>
        </Typography.Text>
      </Row>
    </Col>
  )
}
