import { Clip, Playlist } from '@skribe/web/core'
import { useInvalidateData } from '../../../state'
import { playlistDb } from '@skribe/web/api'

export interface PlaylistManagerProps {
  transcriptId: string
  clips: Clip[]
  canDownloadPlaylist: boolean
}

export function usePlaylistManager(props: PlaylistManagerProps) {
  const { refreshPlaylists } = useInvalidateData()

  function findMatchingClips(playlistClipIds: Playlist['clipIds']): Clip[] {
    if (!playlistClipIds) return []

    return playlistClipIds.map(getClipById).filter(Boolean) as Clip[]
  }

  return {
    findMatchingClips,
    createPlaylist,
    requestDownload,
    update,
    deletePlaylist,
  }

  async function createPlaylist(name: string) {
    await playlistDb.addPlaylist(props.transcriptId, [], name)
    await refreshPlaylists()
  }

  async function requestDownload(playlistId: string) {
    await playlistDb.postPlaylistDownload(playlistId)
    await refreshPlaylists()
  }

  async function update(playlistId: string, clipIds?: string[], name?: string) {
    await playlistDb.patchPlaylist(playlistId, { clipIds, name })
    await refreshPlaylists()
  }

  async function deletePlaylist(id: string) {
    await playlistDb.deletePlaylist(id)
    await refreshPlaylists()
  }

  function getClipById(clipId: string): Clip | undefined {
    return props.clips.find(clip => clipId === clip.id)
  }
}
