import { useNavigate } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import {
  areAnyProcessing,
  Clip,
  getSpeakers,
  getSpeakersV1,
  GetTranscriptResponse,
  mapToSpeakers,
  Monologue,
  Nullable,
  Speaker,
  Transcriber,
} from '@skribe/web/core'
import { clipDb, PostHumanTranscriptBody, transcriptDb } from '@skribe/web/api'
import { OnProgressProps } from 'react-player/base'
import {
  extractFlagFromSession,
  scrollToElementWithClass,
  spotlightWord,
} from '@skribe/web'
import { trackEvent } from '@skribe/web/tracking'
import { TAB } from './transcriptStrings'
import { useQuery } from '@tanstack/react-query'
import { useInvalidateData } from '../state'
import { useTranscriptPlayerContext } from '../context'
import { handleMonologue } from './handleMonologue'
import { CLIPS_QUERY_KEY } from '../state'

export let cursor: number | undefined = undefined
export type TranscriptPageProps = { transcript: GetTranscriptResponse }

export function useTranscriptPage({ transcript }: TranscriptPageProps) {
  // Generic
  const transcriptHeaderRef = useRef<HTMLDivElement>(null)
  const { refreshTranscript } = useInvalidateData()

  useEffect(() => {
    setMonologueTab(
      extractFlagFromSession('humanAddon') && transcript.human_monologues_url
        ? TAB.Human
        : TAB.Rough,
    )
  }, [transcript.human_monologues_url])

  const navigate = useNavigate()
  const back = `/events/${transcript.eventID}`
  // Player

  const {
    seek,
    checkForClipEnd,
    selectClip,
    createClip: _createClip,
    playClip,
    playing,
    selectedClip,
  } = useTranscriptPlayerContext()

  function createClip(type: 'manual' | 'drag') {
    return async (start: number, end: number, name: string) => {
      trackEvent('createClip', {
        parent: transcript.id,
        start,
        end,
        name,
        type,
      })
      await _createClip(start, end, name, transcript.id)
    }
  }

  // Dialogue
  const [duration, setDuration] = useState(transcript.duration_in_seconds)

  function setTranscriptDuration(d: number) {
    setDuration(Math.floor(d))
  }

  const [monologueTab, setMonologueTab] = useState<TAB>(TAB.Rough)

  const [machineMonologues, setMachineMonologues] = useState<
    Monologue[] | undefined
  >()
  const [machineMonologuesError, setMachineMonologuesError] = useState<
    Error | undefined
  >()

  const [humanMonologues, setHumanMonologues] = useState<
    Monologue[] | undefined
  >()
  const [humanMonologuesError, setHumanMonologuesError] = useState<
    Error | undefined
  >()

  const [speakersMachine, setSpeakersMachine] = useState<Speaker[]>([])
  const [speakersHuman, setSpeakersHuman] = useState<Speaker[]>([])

  // Clips
  const [clipIsDownloading, setClipIsDownloading] = useState(false)

  // realSetup

  const { data: clips, error: clipError } = useQuery({
    queryKey: [CLIPS_QUERY_KEY, transcript.id],
    initialData: [],
    refetchOnWindowFocus: false,
    queryFn: async (): Promise<Clip[]> => {
      const clipList = await clipDb.fetchClips(transcript.id)
      setClipIsDownloading(areAnyProcessing(clipList))
      return clipList
    },
    refetchInterval: clipIsDownloading && 5000,
  })

  useEffect(() => {
    fetchMachineMonologues(transcript.monologues_url)
  }, [transcript.monologues_url])

  useEffect(() => {
    fetchHumanMonologues(transcript.human_monologues_url)
  }, [transcript.human_monologues_url])

  // speakers
  useEffect(() => {
    if (machineMonologues) {
      if (transcript.speakers_machine) {
        setSpeakersMachine(
          getSpeakers({
            monologues: machineMonologues,
            speakers: transcript.speakers_machine,
          }),
        )
      } else {
        setSpeakersMachine(
          mapToSpeakers(
            getSpeakersV1({
              monologues: machineMonologues,
              speakers: transcript.speakers,
            }),
          ),
        )
      }
    }
  }, [machineMonologues, transcript.speakers, transcript.speakers_machine])

  useEffect(() => {
    if (humanMonologues) {
      setSpeakersHuman(
        getSpeakers({
          monologues: humanMonologues,
          speakers: transcript.speakers_human,
        }),
      )
    }
  }, [humanMonologues, transcript.speakers_human])

  // updateTranscriptWhileActiveSubscription

  return {
    transcript,
    clips,
    clipError,
    transcriptHeaderRef,
    back,
    seek,
    onDeleteClick,
    onRegeneratePDF,
    onSpeakerChange,
    createClip,
    speakersMachine,
    speakersHuman,
    onProgressUpdate,
    onElementClick: onClipClick,
    monologueTab,
    onMonologueTabChange,
    machineMonologues,
    machineMonologuesError,
    humanMonologues,
    humanMonologuesError,
    orderHumanTranscript,
    duration,
    setTranscriptDuration,
    // Passed for testing
    playClip,
    playing,
    selectedClip,
  }

  // Dialogue
  function onMonologueTabChange(tab: string) {
    setMonologueTab(tab as TAB)
    trackEvent('selectTranscriptTab', { name: tab })
  }

  async function orderHumanTranscript(body?: PostHumanTranscriptBody) {
    await transcriptDb.postHumanTranscript(transcript.id, body)
    trackEvent('orderHumanTranscript', { id: transcript.id, options: body })
    await refreshTranscript()
  }

  function fetchMachineMonologues(url: string | undefined) {
    if (url) {
      handleMonologue(url)
        .then(m => {
          setMachineMonologues(m)
          setMachineMonologuesError(undefined)
        })
        .catch(setMachineMonologuesError)
    }
  }

  function fetchHumanMonologues(url: Nullable<string>) {
    if (url) {
      handleMonologue(url)
        .then(m => {
          setHumanMonologues(m)
          setHumanMonologuesError(undefined)
        })
        .catch(setHumanMonologuesError)
    }
  }

  // TranscriptControls/State Handle/Generic
  async function onDeleteClick() {
    trackEvent('delete_transcript', { transcript })
    await transcriptDb.deleteTranscript(transcript.id)
    trackEvent('deleteTranscript', { id: transcript.id })
    navigate(back)
  }

  async function onRegeneratePDF() {
    if (!transcript) return

    await transcriptDb.regeneratePdf(
      transcript.id,
      tabToTranscriber(monologueTab),
    )
    trackEvent('regeneratePDF', { id: transcript.id })
    await refreshTranscript()
  }

  async function onSpeakerChange(
    index: number,
    name: string,
    type: 'onTranscript' | 'onSelector',
  ) {
    if (!transcript) return
    if (!name) return

    if (monologueTab === TAB.Rough) {
      const speakers_machine = speakersMachine.map(
        replaceSpeakerName(index, name),
      )

      await transcriptDb.updateSpeakerNamesMachine(
        transcript.id,
        speakers_machine,
      )
    } else {
      const speakers_human = speakersHuman.map(replaceSpeakerName(index, name))

      await transcriptDb.updateSpeakerNamesHuman(transcript.id, speakers_human)
    }
    trackEvent('changeSpeaker', { id: transcript.id, type })
    await refreshTranscript()
  }

  // Clips
  function onClipClick(clipID?: string) {
    if (clips) {
      const foundClip = clips.find(c => c.id === clipID)
      if (!foundClip) return

      trackEvent('selectClip', { parent: transcript.id, id: foundClip?.id })
      selectClip(foundClip)
      playClip(foundClip)
    }
  }

  // Player-Monologue-Clip Bridge
  function onProgressUpdate(e: OnProgressProps): void {
    checkForClipEnd(e)

    if (e.playedSeconds) {
      cursor = e.playedSeconds
      scrollToElementWithClass('spotlight-word')
    }

    spotlightWord(cursor)
  }
}

function replaceSpeakerName(
  speaker: number,
  value: string,
): (value: Speaker) => Speaker {
  return s => (s.index === speaker ? { ...s, name: value } : s)
}

function tabToTranscriber(monologueTab: TAB): Transcriber {
  return monologueTab === TAB.Human ? 'human' : 'machine'
}
