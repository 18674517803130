import { Clip, MonologueElement } from '@skribe/web/core'
import {
  usePlaylistsContext,
  useTranscriptPlayerContext,
} from '../../../context'
import { markPlaylistsOutOfDate } from '../../ClipManager/ClipListItem/UseMarkPlaylistAsDirty'
import { useInvalidateData } from '../../../state'
import { getHandlesManager } from './getHandlesManager'
import { clipDb } from '@skribe/web/api'

export function useMonologueElementContainer(
  props: MonologueElementContainerProps,
) {
  const { selectedClip } = useTranscriptPlayerContext()

  const refreshedSelectedClip = props.clips?.find(
    c => c.id === selectedClip?.id,
  )

  const { playlists } = usePlaylistsContext()
  const { refreshClips, refreshPlaylists } = useInvalidateData()

  async function editClip({ start, end }: { start?: number; end?: number }) {
    if (!refreshedSelectedClip) return
    await markPlaylistsOutOfDate(refreshedSelectedClip.id, playlists)
    await clipDb.patchClipLength(
      refreshedSelectedClip.id,
      start ?? refreshedSelectedClip.start,
      end ?? refreshedSelectedClip.end,
    )
    await refreshClips()
    await refreshPlaylists()
  }

  const {
    shouldShowStartHandle,
    shouldShowEndHandle,
    expandStart,
    shrinkStart,
    expandEnd,
    shrinkEnd,
  } = getHandlesManager(
    props.index,
    props.elements,
    editClip,
    refreshedSelectedClip,
    props.previousMonologueElement,
    props.nextMonologueElement,
  )

  return {
    shouldShowStartHandle,
    shouldShowEndHandle,
    expandStart,
    shrinkStart,
    expandEnd,
    shrinkEnd,
    editClip,
  }
}

export type MonologueElementContainerProps = {
  clips: Clip[] | undefined
  elements: MonologueElement[]
  index: number
  element: MonologueElement
  onElementClick: ((clipID?: string) => void) | undefined
  previousMonologueElement?: MonologueElement
  nextMonologueElement?: MonologueElement
}
