import { useParams } from 'react-router-dom'
import { Nullable } from '@skribe/web/core'
import { usePublicClipQuery } from './usePublicClipQuery'

export function useClipPageContainer() {
  const { id } = useParams()
  const { publicClip, error } = usePublicClipQuery(id)

  if (error) throw error
  return { publicClip, getUrl }

  function getUrl(_url: Nullable<string>) {
    return publicClip?.download_url
      ? publicClip?.download_url
      : publicClip?.media_url
  }
}
