import { FC } from 'react'
import ReactDragListView from 'react-drag-listview'
import { Col, List, Row, Typography } from 'antd'
import { Clip, Playlist } from '@skribe/web/core'
import { useClipList } from './useClipList'
import './ClipList.css'
import { PlaylistClipItem } from './ClipListItem/PlaylistClipItem'
import { YoutubeOutlined } from '@ant-design/icons'

type PlaylistClipListProps = {
  playlistId: string
  clips: Clip[]
  playlists?: Playlist[]
  onUpdateSequence: (clips: Clip[]) => void
}

export const PlaylistClipList: FC<PlaylistClipListProps> = ({
  clips,
  onUpdateSequence,
  playlistId,
}) => {
  const { onPlay, onDragEnd, sortedClips, isMediaPlaying, stopPlaying } =
    useClipList({ clips, onUpdateSequence })

  return (
    <ReactDragListView
      nodeSelector=".ant-list-item.draggable"
      lineClassName="dragLine"
      onDragEnd={onDragEnd}
      enableScroll={false}
    >
      <List
        rowKey="id"
        size="large"
        className="clip-list"
        dataSource={sortedClips}
        locale={{ emptyText: <PlaylistClipListPlaceholder /> }}
        renderItem={clip => (
          <PlaylistClipItem
            playlistId={playlistId}
            clip={clip}
            onPlay={() => onPlay(clip)}
            onPause={stopPlaying}
            isPlaying={isMediaPlaying}
          />
        )}
      />
    </ReactDragListView>
  )
}

export function PlaylistClipListPlaceholder() {
  return (
    <Col style={{ textAlign: 'center', marginTop: '20px' }}>
      <Row justify={'center'}>
        <YoutubeOutlined style={{ fontSize: '5rem' }} />
      </Row>
      <Row justify={'center'}>
        <Typography.Text strong type="secondary">
          Add Your First Clip
        </Typography.Text>
      </Row>
      <Row style={{ textAlign: 'start' }}>
        <Typography.Text type="secondary">
          <ul style={{ listStyleType: 'disc' }}>
            <li>Go to the Clips tab.</li>
            <li>Select the clip you want to add.</li>
            <li>Click the three dot menu on the right side.</li>
            <li>Select 'Save to Playlist' and select playlist.</li>
          </ul>
        </Typography.Text>
      </Row>
    </Col>
  )
}
