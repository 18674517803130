import { useInvalidateData } from 'src/app/state'
import { Clip } from '@skribe/web/core'
import { playlistDb } from '@skribe/web/api'
import { useTranscriptPlayerContext } from '../../../context'
import { markPlaylistOutOfDate } from './UseMarkPlaylistAsDirty'
import { trackEvent } from '@skribe/web/tracking'

export type usePlaylistClipItemProps = {
  playlistId: string
  clip: Clip
}

export function usePlaylistClipItem(props: usePlaylistClipItemProps) {
  const { refreshPlaylists } = useInvalidateData()
  const { selectedClip, selectClip } = useTranscriptPlayerContext()

  async function removeFromPlaylist() {
    trackEvent('removeClipFromPlaylist', {
      playlist: props.playlistId,
      clip: props.clip,
    })
    await playlistDb.removeClipFromPlaylist(props.playlistId, props.clip.id)
    await markPlaylistOutOfDate(props.playlistId)
    await refreshPlaylists()
  }

  return { selectedClip, selectClip, removeFromPlaylist }
}
