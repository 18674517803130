import ReactPlayer from 'react-player/file'
import { Alert, Skeleton } from 'antd'
import { PlayCircleOutlined } from '@ant-design/icons'
import { colors, isAudioUrl, STATUS } from '@skribe/web/core'
import React, { useState } from 'react'
import classNames from 'classnames'
import './MediaPlayer.css'

export type ReactPlayerProps = React.ComponentProps<typeof ReactPlayer>
export function MediaPlayer(props: {
  mediaUrl: string | undefined | null
  reactPlayerProps?: Omit<Partial<ReactPlayerProps>, 'url'>
}) {
  const [buffering, setBuffering] = useState(true)
  const { mediaUrl } = props

  if (!mediaUrl || mediaUrl === STATUS.Processing) {
    return (
      <Alert
        type="warning"
        showIcon
        message="Good things come to those who wait"
        description="This media is not available yet."
      />
    )
  }

  if (!ReactPlayer.canPlay(mediaUrl)) {
    return (
      <Alert
        type="warning"
        showIcon
        message="Incompatible Media"
        description="This media is not supported by your browser."
      />
    )
  }

  return (
    <div className="media-player">
      {buffering && (
        <Skeleton.Node active style={{ height: '20vh' }}>
          <PlayCircleOutlined
            style={{ fontSize: 40, color: colors.disabled }}
          />
        </Skeleton.Node>
      )}
      <div
        className={classNames({
          'media-audio': isAudioUrl(mediaUrl),
        })}
      >
        <ReactPlayer
          controls
          width={'100%'}
          url={mediaUrl}
          progressInterval={100}
          onReady={() => {
            setBuffering(false)
          }}
          style={{ display: buffering ? 'none' : 'unset' }}
          {...(props.reactPlayerProps as ReactPlayerProps)}
        />
      </div>
    </div>
  )
}
