import { Button, Result, ResultProps, Tooltip } from 'antd'
import { ErrorComponentProps, useErrorComponent } from './useErrorComponent'
import { CloseCircleOutlined } from '@ant-design/icons'
import React from 'react'

export const ErrorComponent: React.FC<ErrorComponentProps & ResultProps> = ({
  showButton,
  ...args
}) => {
  const { onResetError, resetErrorMessage, status, error } =
    useErrorComponent(args)
  return (
    <Result
      {...args}
      status={status}
      title={error?.name ?? 'Error'}
      subTitle={error?.message ?? 'Something went wrong.'}
      extra={
        showButton && [
          <Button onClick={onResetError} key="call-to-action">
            {resetErrorMessage}
          </Button>,
        ]
      }
    />
  )
}
ErrorComponent.defaultProps = {
  showButton: true,
  status: 'error',
}

export function BasicErrorComponent(props: { message: string }) {
  return (
    <ErrorComponent
      showButton={false}
      status="warning"
      error={{
        message: props.message,
        name: '',
      }}
    />
  )
}

export function ErrorButton() {
  return (
    <Tooltip title={'There was an error with the system, click to refresh'}>
      <Button
        icon={<CloseCircleOutlined color="red" />}
        type="text"
        data-testid="error-button"
        onClick={() => window.location.reload()}
      />
    </Tooltip>
  )
}
