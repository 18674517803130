import { redirect, RouteObject } from 'react-router-dom'
import { BrowserWarning } from './app/BrowserWarning'
import {
  BaseLayout,
  CompatibilityChecker,
  ErrorPage,
  Layout,
} from './app/Layout'
import { MatterPage } from './app/Matters'
import { EventContainer } from './app/Events'
import { ClipPageContainer } from './app/ClipPage'
import { Setup } from './app/Setup'
import { RequireAuth } from './app/RequireAuth'
import { LiaisonPage } from './app/Liaison'
import { ProtectedRoute, RoleRoute } from './app/ProtectedRoute'
import { ERROR_PAGE_NOT_EXISTS, ROLE } from '@skribe/web/core'
import { FronteggAuthProvider } from '@skribe/web/auth'
import { FlagsProvider } from './app/Features/FlagsProvider'
import './app/Layout/Layout.css'
import { SettingsPage } from './app/Settings'
import { TranscriptContainer } from './app/TranscriptPage/TranscriptContainer'
import { PlaylistPageContainer } from './app/PlaylistPage'

export const routes: RouteObject[] = [
  {
    path: '/*',
    element: (
      <FronteggAuthProvider>
        <FlagsProvider>
          <Setup />
        </FlagsProvider>
      </FronteggAuthProvider>
    ),
    errorElement: (
      <FronteggAuthProvider>
        <BaseLayout>
          <ErrorPage />
        </BaseLayout>
      </FronteggAuthProvider>
    ),
    children: [
      getPublicRoutes(),
      { element: <BrowserWarning />, path: 'chrome' },
      {
        element: <CompatibilityChecker />,
        children: [...getLoginRoutes(), getAuthenticatedRoute()],
      },
      {
        path: '*',
        loader: () => {
          throw new Error(ERROR_PAGE_NOT_EXISTS)
        },
      },
    ],
  },
  { path: 'pricing', loader: () => redirect('https://skribe.ai/pricing') },
]

function getPublicRoutes(): RouteObject {
  return {
    element: <Layout />,
    children: [
      { path: 'clip/:id', element: <ClipPageContainer /> },
      { path: 'playlist/:id', element: <PlaylistPageContainer /> },
    ],
  }
}

function getLoginRoutes(): RouteObject[] {
  return [
    {
      path: 'login',
      loader: async () => {
        return redirect('/account/login')
      },
    },
    {
      path: 'legacy-login',
      loader: async () => {
        return redirect('/account/login')
      },
    },
    {
      path: 'create-account',
      loader: async () => {
        return redirect('/account/sign-up')
      },
    },
    {
      path: 'initial-login',
      loader: async () => {
        return redirect('/account/login')
      },
    },
  ]
}

function getAuthenticatedRoute(): RouteObject {
  return {
    element: (
      <RequireAuth>
        <Layout />
      </RequireAuth>
    ),
    children: [
      { path: '', element: <RoleRoute /> },
      {
        element: <ProtectedRoute role={[ROLE.USER, ROLE.LIAISON]} />,
        children: [
          {
            path: 'matters/:id',
            element: <MatterPage />,
          },
          {
            path: 'matters',
            element: <MatterPage />,
          },
          {
            path: 'events/:id',
            element: <EventContainer />,
          },
          { path: 'transcripts/:id', element: <TranscriptContainer /> },
          { path: 'settings', element: <SettingsPage /> },
        ],
      },
      {
        element: <ProtectedRoute role={[ROLE.LIAISON]} />,
        children: [{ path: 'liaison', element: <LiaisonPage /> }],
      },
    ],
  }
}
