import { useEffect, useRef, useState } from 'react'
import debounce from 'lodash/debounce'
import {
  addEventListener,
  forceClick,
  getWordAndSignElements,
  getSelectedText,
  getSelection,
  removeEventListener,
} from './dom'
import { truncateText } from '@skribe/web/core'
import { TimeSpan, extractTimeSpanForWord, getTimeSpanOf } from './domTimeSpans'
import { getSelectedElements } from './dom'

const DEBOUNCE_TIME_IN_MS = 500

export const useSelection = () => {
  const ref = useRef<HTMLDivElement>(null)

  const [transcriptSelection, setTranscriptSelection] =
    useState<null | TimeSpan>(null)
  const [text, setText] = useState<null | string>(null)
  const [fullText, setFullText] = useState('')

  const debounceHandleSelection = debounce(
    () => handleSelection(ref, setSelection, resetSelection),
    DEBOUNCE_TIME_IN_MS,
  )

  useEffect(() => {
    addEventListener('selectstart', debounceHandleSelection)
    addEventListener('selectionchange', debounceHandleSelection)

    return () => {
      removeEventListener('selectstart', debounceHandleSelection)
      removeEventListener('selectionchange', debounceHandleSelection)
    }
  })

  return {
    selection: transcriptSelection,
    transcriptText: text,
    fullText,
    ref,
  }

  function setSelection(start: number, end: number, selectedText: string) {
    setTranscriptSelection({ start, end })
    setText(truncateText(selectedText))
    setFullText(selectedText)
  }

  function resetSelection() {
    setTranscriptSelection(null)
    setText(null)
    setFullText('')
  }
}

export function handleSelection(
  ref: React.RefObject<HTMLDivElement>,
  setSelection: (start: number, end: number, selectedText: string) => void,
  resetSelection: () => void,
) {
  const selection = getSelection()
  const textElements = getWordAndSignElements(ref)
  if (noValidSelection(ref, selection) || !textElements) {
    resetSelection()
    return
  }
  const selectedElements = getSelectedElements(textElements, selection)
  const selectedTimeSpans = selectedElements.map(extractTimeSpanForWord)
  const selectedText = getSelectedText(selectedElements)

  const { start, end } = getTimeSpanOf(selectedTimeSpans)
  if (start < end) {
    setSelection(start, end, selectedText)
    forceClick(end)
  } else {
    resetSelection()
  }
}

function noValidSelection(
  ref: React.RefObject<HTMLDivElement>,
  selection: Selection | null,
): selection is null {
  const notLinkedToADomElement = !ref.current
  const noTextSelected = !selection || selection.isCollapsed
  return notLinkedToADomElement || noTextSelected
}
