import { Clip } from '@skribe/web/core'
import { TAB } from '../transcriptStrings'
import { useEffect, useState } from 'react'
import { addSearchHighlight, removeCurrentHighlight } from '@skribe/web'
import { clipDb } from '@skribe/web/api'

export function useTranscriptControls({
  clips,
  tab,
}: {
  clips: Clip[]
  tab: TAB
}) {
  const [value, setValue] = useState<string>('')
  const regenerateClips = () =>
    clipDb.generateClips(clips?.map(v => v.id) ?? [])

  useEffect(() => {
    setValue('')
  }, [tab])

  useEffect(() => {
    if (value === '') {
      removeCurrentHighlight()
      addSearchHighlight([])
    }
  }, [value])

  return { value, setValue, regenerateClips }
}
