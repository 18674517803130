import React from 'react'
import { Button, Progress, Tooltip } from 'antd'
import { DownloadOutlined, StopOutlined } from '@ant-design/icons'
import { DownloadStatus } from '@skribe/web/core'
import { ReactComponent as ReadyToDownload } from './ready-to-download.svg'

import { ErrorButton, LoadingComponent } from '../../../components'

import { DownloadItemProps, useDownloadItem } from './useDownloadItem'
import { trackEvent } from '@skribe/web/tracking'

export const DownloadItem: React.FC<DownloadItemProps> = props => {
  const { downloadStatus, requestDownload, download, error } =
    useDownloadItem(props)

  if (error) return <ErrorButton />
  return (
    <>
      {[DownloadStatus.notStarted, DownloadStatus.outOfDate].includes(
        downloadStatus,
      ) && (
        <Tooltip
          title={
            downloadStatus === DownloadStatus.notStarted
              ? `Create ${props.itemName} Media`
              : `Regenerate ${props.itemName} Media`
          }
        >
          <Button
            disabled={props.disabled}
            icon={<DownloadOutlined />}
            data-testid="not-started-download-button"
            type="text"
            onClick={requestDownload}
          />
        </Tooltip>
      )}

      {downloadStatus === DownloadStatus.processing &&
        (props.item.progress ? (
          <Tooltip
            title={`${props.itemName} Download ${props.item.progress}% Complete`}
          >
            <Button
              type="text"
              disabled
              data-testid="processing-download-button"
              icon={
                <Progress
                  type="circle"
                  percent={props.item.progress}
                  size={20}
                />
              }
            />
          </Tooltip>
        ) : (
          <Tooltip title="Loading">
            <LoadingComponent />
          </Tooltip>
        ))}
      {downloadStatus === DownloadStatus.ready && (
        <Tooltip title={`Download ${props.itemName}`}>
          <Button
            icon={<ReadyToDownload />}
            type="text"
            onClick={download}
            data-testid="ready-download-button"
          />
        </Tooltip>
      )}
      {downloadStatus === DownloadStatus.failed && (
        <Tooltip
          onOpenChange={() => {
            trackEvent('Download Failure', { props })
          }}
          title={`Sorry, this download has failed.`}
        >
          <Button
            icon={<StopOutlined />}
            type="text"
            disabled
            data-testid="failed-download-button"
          />
        </Tooltip>
      )}
    </>
  )
}
