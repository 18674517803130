import { FC, MouseEventHandler } from 'react'
import { Button, Image, List, Tooltip, Typography } from 'antd'
import { Link } from 'react-router-dom'
import { GlobalToken } from 'antd/es/theme'
import { Matter, UserGroup } from '@skribe/web/core'
import { ActionMenu, createDeleteDropdownItem } from '../components'
import { ItemType } from 'antd/es/menu/hooks/useItems'
import {
  createPinMatterDropdownItem,
  useMatterListItem,
} from './useMatterListItem'
import { PushpinFilled } from '@ant-design/icons'
import { createShareDropdownItem } from './createShareDropdownItem'

export type MatterListItemProps = {
  matter: Matter
  selected: boolean
  onSelect: MouseEventHandler
  onMove: () => any
  userGroups: UserGroup[]
  themeToken: GlobalToken
  onDelete: (id: string | undefined) => Promise<void>
}

export const MatterListItem: FC<MatterListItemProps> = ({
  matter,
  selected,
  onSelect,
  onMove,
  themeToken,
  userGroups,
  onDelete,
}) => {
  const { pinMatter, unpinMatter, onShare, shouldShowPinIcon } =
    useMatterListItem({
      matter,
      onMove,
    })

  return (
    <List.Item
      data-testid="matterListItem"
      color={'red'}
      onClick={onSelect}
      style={selected ? { backgroundColor: themeToken.colorBgTextActive } : {}}
      actions={[
        <ActionMenu
          id={matter.id}
          items={
            [
              createPinMatterDropdownItem(pinMatter),
              createShareDropdownItem({
                onShare,
                userGroups,
                id: matter.groups?.toString() ?? '',
              }),
              userGroups.length !== 1 ? { type: 'divider' } : null,
              createDeleteDropdownItem(() => onDelete(matter.id)),
            ].filter(Boolean) as ItemType[]
          }
        />,
      ]}
    >
      <List.Item.Meta
        avatar={
          <>
            {shouldShowPinIcon() && (
              <Tooltip title="Unpin Matter">
                <Button
                  type="text"
                  onClick={unpinMatter}
                  icon={<PushpinFilled />}
                />
              </Tooltip>
            )}
            <Image
              src="/matter-item.svg"
              alt="Matter"
              preview={false}
              style={{ color: '6C757D' }}
            />
          </>
        }
        title={
          <>
            <Typography.Text ellipsis>
              <Link data-testid="matterLink" to={''}>
                {matter.name}
              </Link>
            </Typography.Text>
          </>
        }
      />
    </List.Item>
  )
}
