import { FC } from 'react'
import { LoadingComponent } from '../components'
import EventPage from './EventPage'
import { useEventContainer } from './useEventContainer'

export const EventContainer: FC = props => {
  const { event } = useEventContainer()

  if (!event) return <LoadingComponent />

  return <EventPage event={event} />
}
