import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { GetTranscriptResponse, STATUS } from '@skribe/web/core'
import { useDocumentTitle } from '../utilities'
import { transcriptDb } from '@skribe/web/api'
import { useTranscriptPageContext } from '../context/TranscriptPageContext'
import { useFeature } from 'flagged'
import { TRANSCRIPT_QUERY_KEY } from '../state'

export function useTranscriptContainer() {
  const { id } = useParams()
  const [inProgress, setInProgress] = useState(false)
  const useAiChat = useFeature('aiChat')

  const { data: transcript, error } = useQuery({
    queryKey: [TRANSCRIPT_QUERY_KEY, id],
    queryFn: (): Promise<GetTranscriptResponse> =>
      transcriptDb.getTranscript(id),
    refetchInterval: inProgress && 10000,
    retry: false,
    refetchOnWindowFocus: false,
  })
  useEffect(() => {
    setInProgress(
      transcript?.status === 'in_progress' ||
        transcript?.status === 'pending' ||
        transcript?.pdf_url === STATUS.Processing,
    )
  }, [transcript?.status, transcript?.pdf_url])

  const { setTranscriptId } = useTranscriptPageContext()
  useEffect(() => {
    if (useAiChat && transcript?.id && setTranscriptId) {
      setTranscriptId(transcript.id)
    }
  }, [useAiChat, transcript?.id, setTranscriptId])

  useDocumentTitle({
    name: transcript?.name,
    type: 'Transcript',
  })
  return {
    transcript,
    error,
  }
}
