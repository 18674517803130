import { ReactNode } from 'react'
import { Card, Col, Row, Typography } from 'antd'
import { PublicClip } from '@skribe/web/api'
import { ClockCircleOutlined } from '@ant-design/icons'
import { timestamp } from '../utilities/timeUtilities'
import { Dialogue } from '../TranscriptPage/Dialogue/Dialogue'
import './ClipPage.scss'

export function ClipPage(props: {
  publicClip: PublicClip
  breakpoints?: Record<string, number>
  player?: ReactNode
  navigationButtons?: ReactNode
}) {
  return (
    <div id={'clip-page'} style={{ maxWidth: 'unset' }}>
      <Row justify="center" style={{ flex: 1 }}>
        <Col {...props.breakpoints} style={{ flex: 1 }}>
          <Card style={{ marginBottom: '0.25em' }}>
            <div className="clip-video">{props.player}</div>
            <Typography.Paragraph style={{ margin: 0 }}>
              <ClockCircleOutlined />
              {'  '}
              {timestamp(props.publicClip.start)} -{' '}
              {timestamp(props.publicClip.end)}
            </Typography.Paragraph>
            <Typography.Title
              level={4}
              className={'no-overflow'}
              style={{ margin: 0 }}
            >
              {props.publicClip.name}
            </Typography.Title>
          </Card>
        </Col>
      </Row>
      <Row justify="center">
        <Col {...props.breakpoints} style={{ flex: 1 }}>
          <Card className="transcript-card" size="small">
            <Row justify={'space-between'} align={'middle'}>
              <Col>
                <Typography.Title level={5}>Transcript</Typography.Title>
              </Col>
              <Col>{props?.navigationButtons}</Col>
            </Row>
          </Card>
          <Card
            className="clip-dialogue"
            style={{
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            }}
          >
            <Dialogue
              monologues={props.publicClip.monologues}
              speakers={props.publicClip.speakers ?? []}
            />
          </Card>
        </Col>
      </Row>
    </div>
  )
}
