import { useContext, useEffect } from 'react'
import { MatterWithEvents } from '@skribe/web/core'
import { useNavigate, useParams } from 'react-router-dom'
import { useIsTrial } from '../User/useIsTrial'
import { MatterContext } from './MatterProvider'
import { useUserContext } from '../User/UserProvider'
import { matterDb } from '@skribe/web/api'
import { useQuery } from '@tanstack/react-query'
import { MATTERS_QUERY_KEY } from '../state'

export function useMatterPage() {
  const params = useParams()
  const navigate = useNavigate()
  const {
    data: matters,
    error,
    isLoading,
  } = useQuery({
    queryKey: [MATTERS_QUERY_KEY],
    queryFn: matterDb.list,
  })

  const { selectedMatter, selectMatter } = useContext(MatterContext)
  const isTrial = useIsTrial()
  const {
    user: { exampleTranscriptId },
    refreshUser,
  } = useUserContext()

  useEffect(() => {
    if (matters) {
      const matter = findMatter(params?.id, matters)
      selectMatter(matter)
    }
  }, [params, selectMatter, matters])

  useEffect(() => {
    if (isTrial && exampleTranscriptId) {
      navigate(`/transcripts/${exampleTranscriptId}`)
      refreshUser()
    }
  }, [isTrial, navigate, exampleTranscriptId, refreshUser])

  if (error) throw error
  return {
    matters,
    isLoading,
    selectedMatter,
  }

  function findMatter(
    id: string | undefined,
    matters: MatterWithEvents[],
  ): MatterWithEvents | null {
    return matters?.find(item => item.id === id) ?? null
  }
}
