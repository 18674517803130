import { useState } from 'react'
import { PublicClip } from '@skribe/web/api'
import { OnProgressProps } from 'react-player/base'
import { scrollToElementWithClass, spotlightWord } from '@skribe/web'

export function useMediaPlayer(props: {
  clips?: PublicClip[] | undefined
  selectedClip: PublicClip | undefined
  setSelectedClip?: (clip: PublicClip) => void
}) {
  const { clips, selectedClip, setSelectedClip } = props
  const [progressPercentage, setProgressPercentage] = useState<
    number | undefined
  >(undefined)
  const [playing, setPlaying] = useState(false)

  function onPause() {
    setPlaying(false)
  }
  function onPlay() {
    setPlaying(true)
  }
  function onEnd() {
    if (clips && selectedClip) {
      const currentClipIndex = clips.findIndex(
        clip => clip.id === selectedClip.id,
      )
      if (currentClipIndex < 0) {
        console.error('current clip not found in playlist clip list')
        return
      }
      if (currentClipIndex < clips.length - 1) {
        const nextClip = clips[currentClipIndex + 1]
        selectClip(nextClip)
        setPlaying(true)
      }
    }
  }

  function selectClip(clip: PublicClip) {
    if (setSelectedClip) {
      if (selectedClip && clip.id !== selectedClip?.id) onPause()
      setSelectedClip(clip)
    }
  }

  function playClip(clip: PublicClip) {
    selectClip(clip)
    setPlaying(true)
  }

  const onProgress = (state: OnProgressProps) => {
    if (selectedClip) {
      updateProgress(state.playedSeconds, selectedClip)
      highlightCurrentWord(state.playedSeconds, selectedClip)
    }
  }

  function highlightCurrentWord(
    playedSeconds: number,
    selectedClip: PublicClip,
  ) {
    const cursor = playedSeconds + selectedClip.start
    spotlightWord(cursor)
    scrollToElementWithClass('spotlight-word')
  }

  function updateProgress(playedSeconds: number, selectedClip: PublicClip) {
    const duration = selectedClip.end - selectedClip.start
    const percentage = (playedSeconds / duration) * 100
    setProgressPercentage(percentage)
  }

  return {
    playing,
    onPause,
    playClip,
    onProgress,
    selectClip,
    onEnd,
    onPlay,
    progressPercentage,
  }
}
