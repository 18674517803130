/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react'
import { DownloadStatus, statusFor } from '@skribe/web/core'
import { downloadPresignedUrl } from '@skribe/web'

export interface DownloadItemProps {
  item: {
    download_url?: string | null
    progress?: number | null
    isDownloadUrlOutOfDate?: boolean | undefined
    id: string
  }
  requestDownload: () => Promise<void>
  trackDownload?: () => void
  itemName?: string
  disabled?: boolean
}

export function useDownloadItem(props: DownloadItemProps) {
  const downloadOutOfDate = !!props.item.isDownloadUrlOutOfDate
  const [downloadStatus, setDownloadStatus] = useState(
    statusFor(props.item.download_url, downloadOutOfDate),
  )
  const [shouldDownloadWhenReady, setShouldDownloadWhenReady] = useState(false)
  const [error, setError] = useState<Error | undefined>(undefined)
  const download = useCallback(() => {
    if (props.trackDownload) props.trackDownload()
    if (props.item.download_url) {
      downloadPresignedUrl(props.item.download_url)
    }
  }, [props.item.download_url])

  useEffect(() => {
    setDownloadStatus(statusFor(props.item.download_url, downloadOutOfDate))
  }, [props.item.download_url])

  useEffect(() => {
    if (
      shouldDownloadWhenReady &&
      statusFor(props.item.download_url, downloadOutOfDate) ===
        DownloadStatus.ready
    ) {
      download()
      setShouldDownloadWhenReady(false)
    }
  }, [props.item.download_url, shouldDownloadWhenReady, download])

  return {
    downloadStatus,
    requestDownload,
    download,
    downloadOnReady: shouldDownloadWhenReady,
    error,
  }

  async function requestDownload() {
    try {
      await props.requestDownload()
      setDownloadStatus(DownloadStatus.processing)
      setShouldDownloadWhenReady(true)
    } catch (error) {
      setError(error as any as Error)
    }
  }
}
