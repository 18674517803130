import { Playlist } from '@skribe/web/core'
import { publicAPI, secureAPI } from './client'
import { PublicClip } from './clipDb'

export type PublicPlaylist = Playlist & { clips: PublicClip[] }

export async function fetchPlaylists(
  transcriptId: string,
): Promise<Playlist[]> {
  return (await secureAPI.get(
    'rev',
    `/playlists?transcriptId=${transcriptId}`,
    {},
  )) as Playlist[]
}

export async function addPlaylist(
  transcriptId: string,
  clipIds: string[],
  name: string,
) {
  return (await secureAPI.post('rev', `/playlists`, {
    body: {
      transcriptId,
      name,
      clipIds,
    },
  })) as Playlist
}
export async function deletePlaylist(transcriptId: string) {
  await secureAPI.del('rev', `/playlists/${transcriptId}`, {})
}
export async function patchPlaylist(
  playlistId: string,
  fieldsToUpdate: {
    clipIds?: string[]
    name?: string
    isDownloadUrlOutOfDate?: boolean
  },
) {
  return (await secureAPI.patch('rev', `/playlists/${playlistId}`, {
    body: fieldsToUpdate,
  })) as Playlist
}

export async function removeClipFromPlaylist(
  playlistId: string,
  clipId: string,
) {
  return (await secureAPI.post('rev', `/playlists/${playlistId}/remove`, {
    body: {
      clipId,
    },
  })) as Playlist
}
export async function postPlaylistDownload(playlistId: string) {
  return secureAPI.post('rev', `/playlists/${playlistId}/download`, {})
}
export async function fetchPublicPlaylist(id: string | undefined) {
  return (await publicAPI.get(
    'rev',
    `/playlists/${id}/public`,
    {},
  )) as PublicPlaylist
}
export async function addClipsToPlaylist(playlist: Playlist, clips: string[]) {
  const clipIds = removeDuplicates([...(playlist?.clipIds ?? []), ...clips])
  await patchPlaylist(playlist.id, { clipIds, isDownloadUrlOutOfDate: true })
}

export function removeDuplicates(arr: string[]): string[] {
  return Array.from(new Set(arr))
}
