import { playlistDb, PublicClip, PublicPlaylist } from '@skribe/web/api'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { Dispatch, SetStateAction, useEffect } from 'react'
import { areAnyProcessing } from '@skribe/web/core'
import { PLAYLIST_QUERY_KEY } from '../state/QueryKeys'

export function usePlaylistPageContainer(
  setSelectedClip: Dispatch<SetStateAction<PublicClip | undefined>>,
  selectedClip: PublicClip | undefined,
) {
  const { id } = useParams()

  const { playlist } = usePublicPlaylistQuery(id, setSelectedClip, selectedClip)
  useEffect(() => {
    updateSelectedClipIfDownloadUrlChanged(
      selectedClip,
      playlist,
      setSelectedClip,
    )
  }, [setSelectedClip, playlist, selectedClip])

  return { playlist }
}

export function updateSelectedClipIfDownloadUrlChanged(
  selectedClip: PublicClip | undefined,
  playlist: PublicPlaylist | undefined,
  setSelectedClip: (s: PublicClip) => void,
) {
  if (!(selectedClip && playlist?.clips)) return

  const matchingClip = playlist.clips.find(
    clip =>
      clip.id === selectedClip.id &&
      selectedClip.download_url !== clip.download_url,
  )
  if (matchingClip) setSelectedClip(matchingClip)
}

function usePublicPlaylistQuery(
  id: string | undefined,
  setSelectedClip: Dispatch<SetStateAction<PublicClip | undefined>>,
  selectedClip: PublicClip | undefined,
) {
  const { data: playlist, error } = useQuery({
    queryKey: [PLAYLIST_QUERY_KEY, id],
    refetchOnWindowFocus: false,
    queryFn: () => getPlaylist(id, selectedClip, setSelectedClip),
    refetchInterval: fetchResult => {
      return refetchIfClipsNotGenerated(fetchResult.state.data?.clips)
    },
  })
  if (error) throw error
  return { playlist }
}

export async function getPlaylist(
  id: string | undefined,
  selectedClip: PublicClip | undefined,
  setSelectedClip: Dispatch<SetStateAction<PublicClip | undefined>>,
) {
  const playlist = await playlistDb.fetchPublicPlaylist(id)
  if (playlist.clips.length > 0 && !selectedClip)
    setSelectedClip(playlist.clips[0])
  return playlist
}

export function refetchIfClipsNotGenerated(
  clips: PublicClip[] | undefined,
): number | false | undefined {
  if (!clips) return 3000
  if (areAnyProcessing(clips)) return 3000
  return false
}
