import { Button, Dropdown, MenuProps, Row, Typography } from 'antd'
import React from 'react'
import { useLatestNews } from './useLatestNews'
import { Changelog } from './CHANGELOG'
import { BellOutlined } from '@ant-design/icons'
import { toLocalDate } from '@skribe/web/date'
import { externalNavigate } from '@skribe/web'
import { trackEvent } from '@skribe/web/tracking'

export const LatestNews: React.FC = () => {
  const { news } = useLatestNews()

  function notificationToItem(updates: Changelog[]): MenuProps['items'] {
    function onClick(link: string) {
      if (!link) {
        return () => {}
      }
      return () => {
        trackEvent('redirectedToNotification', { link })
        externalNavigate(link)
      }
    }
    return updates.map(u => ({
      key: u.title,
      title: u.title,
      label: (
        <div
          style={{ width: '15rem', cursor: 'pointer' }}
          onClick={onClick(u.link)}
        >
          <Row>{toLocalDate(u.date)}</Row>
          <Row>
            <Typography.Title level={5}>{u.title}</Typography.Title>
          </Row>
          <Row>{u.description}</Row>
        </div>
      ),
    }))
  }

  if (news.length === 0) return <></>

  return (
    <Dropdown
      menu={{ items: news && notificationToItem(news) }}
      placement="bottomRight"
      onOpenChange={open => {
        if (open) {
          trackEvent('openedNotifications')
        }
      }}
      trigger={['hover']}
    >
      <Button size="large" type={'text'} icon={<BellOutlined />} />
    </Dropdown>
  )
}
