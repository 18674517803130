import { publicAPI, secureAPI } from './client'
import { Clip, PublicItem } from '@skribe/web/core'

export type PublicClip = Clip & PublicItem

export async function fetchClip(id: string | undefined) {
  return (await publicAPI.get('rev', `/clips/${id}/public`, {})) as PublicClip
}

export async function postClipDownload(clipId: string) {
  return secureAPI.post('rev', `/clips/${clipId}/download`, {})
}
export async function generateClips(clipIds: string[]) {
  return secureAPI.post('rev', `/clips/generate-multiple`, {
    body: { clips: clipIds },
  })
}

export async function deleteClip(id: string) {
  await secureAPI.del('rev', `/clips/${id}`, {})
}

export async function updateClipSequence(clips: Clip[]) {
  clips.forEach(c => {
    secureAPI.patch('rev', `/clips/${c.id}`, {
      body: { sequence: c.sequence },
    })
  })
}

export async function patchClipName(id: string, name: string) {
  return (await secureAPI.patch('rev', `/clips/${id}`, {
    body: { name },
  })) as Clip
}

export async function patchClipLength(
  id: string,
  startTimeStamp: number,
  endTimeStamp: number,
) {
  return (await secureAPI.patch('rev', `/clips/${id}`, {
    body: { start: startTimeStamp, end: endTimeStamp },
  })) as Clip
}

export async function fetchClips(
  transcriptId: string | undefined,
): Promise<Clip[]> {
  if (!transcriptId) return []

  return (await secureAPI.get(
    'rev',
    `/clips?transcriptId=${transcriptId}`,
    {},
  )) as Clip[]
}

export async function addClip(
  transcriptId: string,
  start: number,
  end: number,
  name: string,
) {
  try {
    return (await secureAPI.post('rev', `/clips`, {
      body: {
        name,
        start,
        end,
        transcriptId,
      },
    })) as Clip
  } catch (error) {
    console.error(error)
  }
}
