import { PublicPlaylist, PublicClip } from '@skribe/web/api'

export function usePlaylistPageDataSource(
  playlist: PublicPlaylist | undefined,
  selectedClip: PublicClip | undefined,
  progressPercentage: number | undefined,
  setSelectedClip: Function,
) {
  const currentIndex =
    playlist?.clips.findIndex(c => c.id === selectedClip?.id) ?? 0
  const dataSource = playlist?.clips.map(clip => ({
    ...clip,
    progress: clip.id === selectedClip?.id ? progressPercentage : undefined,
  }))
  const onPrevious = () => {
    if (playlist) {
      setSelectedClip(playlist.clips[currentIndex - 1])
    }
  }
  const disablePrevious = currentIndex === 0
  const onNext = () => {
    if (playlist) {
      setSelectedClip(playlist.clips[currentIndex + 1])
    }
  }
  const disableNext = playlist && currentIndex === playlist.clips.length - 1
  return { dataSource, onPrevious, onNext, disablePrevious, disableNext }
}
