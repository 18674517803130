import { Clip, MonologueElement } from '@skribe/web/core'
import { HandlesVisibilityManager } from './HandlesVisibilityManager'

export function getHandlesManager(
  index: number,
  elements: MonologueElement[],
  editClip: (clipTime: { start?: number; end?: number }) => Promise<void>,
  clip?: Clip,
  previousMonologueElement?: MonologueElement,
  nextMonologueElement?: MonologueElement,
) {
  const manager = new HandlesVisibilityManager(
    index,
    elements,
    clip,
    previousMonologueElement,
    nextMonologueElement,
  )

  async function expandStart() {
    const start = manager.getPreviousValidElement()?.ts
    await editClip({ start })
  }

  async function shrinkStart() {
    let start = manager.getNextValidElement()?.ts
    await editClip({ start })
  }

  async function expandEnd() {
    const end = manager.getNextValidElement()?.end_ts
    await editClip({ end })
  }

  async function shrinkEnd() {
    const end = manager.getPreviousValidElement()?.end_ts
    await editClip({ end })
  }

  return {
    shouldShowStartHandle: manager.calculateShouldShowStartHandle(),
    shouldShowEndHandle: manager.calculateShouldShowEndHandle(),
    expandStart,
    shrinkStart,
    expandEnd,
    shrinkEnd,
  }
}
