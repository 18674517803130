import dayjs from 'dayjs'

export const CHANGELOG: Changelog[] = [
  {
    date: dayjs('2024-8-21'),
    title: 'Playlist for Clips!',
    description:
      'Create, organize, and download a playlist of your important clips.',
    link: '',
  },
  {
    date: dayjs('2024-8-20'),
    title: 'Pin Important Matters',
    description:
      'Keep important matters at the top of the list? Click more and pin it to make it first..',
    link: '',
  },
  {
    date: dayjs('2024-8-6'),
    title: 'Edit Clips In App',
    description:
      'Now, you can tweak and trim your clips right inside our app. Select the More icon and edit the time or word.',
    link: '',
  },
  {
    date: dayjs('2024-7-15'),
    title: 'Chat With Your Transcript',
    description:
      'Summarize, search, and generate video clips in a few clicks. All powered by GenAI.',
    link: 'https://skribe.ai/skribes-conversational-ai/',
  },
]

export type Changelog = {
  date: dayjs.Dayjs
  title: string
  description: string
  link: string
}
