import { Clip, Playlist } from '@skribe/web/core'
import { Button, Col, List, Row, theme, Tooltip } from 'antd'
import {
  ActionMenu,
  createDeleteDropdownItem,
  EditableText,
} from 'src/app/components'
import { DownloadItem } from '../../ClipManager/ClipListItem/DownloadItem'
import { trackEvent } from '@skribe/web/tracking'
import { PlaylistClipList } from '../../ClipManager/PlaylistClipList'
import { useTranscriptPlayerContext } from 'src/app/context'
import { PlayPauseButton } from '@skribe/web/components'
import { LinkOutlined } from '@ant-design/icons'
import { formatPlaylistDuration } from '@skribe/web/date'
import { useFeature } from 'flagged'
import { copyToClipboard } from '@skribe/web'
import { clipDb } from '@skribe/web/api'
import { useOnCopyNotification } from 'src/app/Notifications/onCopy'

export type PlaylistItemProps = {
  clips: Clip[]
  playlist: Playlist
  delete: (id: string) => Promise<void>
  requestDownload?: (id: string) => Promise<void>
  update: (
    playlistId: string,
    clipIds?: string[],
    name?: string,
  ) => Promise<void>
}

export function PlaylistItem(props: PlaylistItemProps) {
  const { token } = theme.useToken()
  const {
    playClips,
    stoppedPlaying,
    playing,
    selectedPlaylistId,
    setSelectedPlaylistId,
  } = useTranscriptPlayerContext()
  const sharePlaylist = useFeature('playlist-share')
  const updateClipOrder = (clips: Clip[]) => {
    trackEvent('updateClipOrder', { playlist: props.playlist })
    props.update(
      props.playlist.id,
      clips.map(clip => clip.id),
      undefined,
    )
  }
  const updateName = (newName: string) => {
    trackEvent('updatePlaylistName', { playlist: props.playlist, newName })
    props.update(props.playlist.id, undefined, newName)
  }
  const deletePlaylist = () => {
    trackEvent('deletePlaylist', { playlist: props.playlist })
    props.delete(props.playlist.id)
  }
  const requestDownload = async () => {
    if (props.requestDownload) {
      const eventName = props.playlist.isDownloadUrlOutOfDate
        ? 'regeneratePlaylistDownload'
        : 'generatePlaylistDownload'
      trackEvent(eventName, { playlist: props.playlist })

      return props.requestDownload(props.playlist.id)
    }
  }
  const trackDownload = () =>
    trackEvent('downloadPlaylist', { playlist: props.playlist })
  const play = () => {
    trackEvent('playPlaylist', { playlist: props.playlist })
    playClips(props.clips)
  }

  const isEmpty = props.playlist.clipIds?.length === 0

  function selectPlaylist() {
    setSelectedPlaylistId(props.playlist.id)
  }
  const playlistIsSelected = props.playlist.id === selectedPlaylistId

  function showSelectedStyle() {
    return playlistIsSelected
      ? { backgroundColor: token.colorBgTextActive }
      : {}
  }
  const { onCopy, notificationContext } = useOnCopyNotification()
  //TODO: refactor to reuse with copyClipUrl
  function copyPlaylistUrl(): void {
    let url = new URL(`${window.location.origin}/playlist/${props.playlist.id}`)
    copyToClipboard(url.toString())
    if (!props.playlist.download_url) {
      console.log('generating playlist downloads')
      if (props.playlist.clipIds?.length) {
        clipDb.generateClips(props.playlist.clipIds)
      }
    }
    trackEvent('copyPlaylistLink', { playlist: props.playlist })
    onCopy && onCopy()
  }
  return (
    <List.Item>
      {notificationContext}
      <div
        style={{ ...showSelectedStyle(), width: '100%' }}
        onClick={selectPlaylist}
      >
        <Row className="playlist-header" style={{ width: '100%' }}>
          <Col span={2} style={{ alignContent: 'center' }}>
            <PlayPauseButton
              isSelected={playlistIsSelected}
              playing={playing}
              onPause={stoppedPlaying}
              onPlay={play}
            />
          </Col>
          <Col span={21} offset={1}>
            <Row justify={'space-between'}>
              <Col style={{ alignSelf: 'center' }}>
                <Row justify={'space-between'}>
                  {`${props.playlist.clipIds?.length} Clip(s):`}
                  &nbsp;
                  {formatPlaylistDuration(props.clips)}
                </Row>
              </Col>
              <Col>
                <Row>
                  {props.requestDownload && (
                    <DownloadItem
                      disabled={isEmpty}
                      itemName="Playlist"
                      requestDownload={requestDownload}
                      trackDownload={trackDownload}
                      item={props.playlist}
                    />
                  )}
                  {sharePlaylist ? (
                    <Tooltip title="Copy share link to clipboard">
                      <Button
                        disabled={isEmpty}
                        onClick={copyPlaylistUrl}
                        icon={<LinkOutlined />}
                        type="text"
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Coming Soon">
                      <Button disabled icon={<LinkOutlined />} type="text" />
                    </Tooltip>
                  )}
                  <ActionMenu
                    id="clipActionMenu"
                    items={[createDeleteDropdownItem(deletePlaylist)]}
                  />
                </Row>
              </Col>
            </Row>
            <Row align={'middle'} style={{ width: '100%' }}>
              <Col span={12}>
                <EditableText
                  startingValue={props.playlist.name ?? 'name'}
                  onEdit={updateName}
                  testId="playlistName"
                />
              </Col>
              <Col></Col>
            </Row>
          </Col>
        </Row>
        {playlistIsSelected && (
          <Row className="playlist-clip-list" style={{ width: '100%' }}>
            <PlaylistClipList
              playlistId={props.playlist.id}
              clips={props.clips}
              onUpdateSequence={updateClipOrder}
            />
          </Row>
        )}
      </div>
    </List.Item>
  )
}
