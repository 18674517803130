import React, { useEffect } from 'react'
import { Grid, Switch, Tooltip } from 'antd'
import { useTranscriptPageContext } from '../context/TranscriptPageContext'
import { SwitchChangeEventHandler } from 'antd/es/switch'
import { trackEvent } from '@skribe/web/tracking'
import { useChatPanel } from './UseChatPanel'

export const ChatPanelSwitch = () => {
  const shouldUseChatPanel = useChatPanel()
  const context = useTranscriptPageContext()
  const { setIsDrawerOpen } = context
  const onToggle: SwitchChangeEventHandler = checked => {
    trackEvent(checked ? 'openAIPanel' : 'closeAIPanel')
    context.setIsDrawerOpen(checked)
  }
  const { xl } = Grid.useBreakpoint()

  useEffect(() => {
    if (!xl) {
      setIsDrawerOpen(false)
    } else {
      setIsDrawerOpen(true)
    }
  }, [xl, setIsDrawerOpen])
  if (!shouldUseChatPanel) {
    return <></>
  }
  return (
    <Tooltip title={'Chat with Transcript'}>
      <Switch value={context.isDrawerOpen} onChange={onToggle} />
    </Tooltip>
  )
}
