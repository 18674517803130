import { useQuery } from '@tanstack/react-query'
import { trackEvent } from '@skribe/web/tracking'
import { getSpeakers, isProcessingOrStale } from '@skribe/web/core'
import { clipDb, PublicClip } from '@skribe/web/api'
import { CLIP_QUERY_KEY } from '../state'

export function usePublicClipQuery(id: string | undefined) {
  const { data: publicClip, error } = useQuery({
    queryKey: [CLIP_QUERY_KEY, id],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const clip = await clipDb.fetchClip(id)
      trackEvent('viewClip', { clip })
      return { ...clip, speakers: getSpeakers(clip) }
    },
    refetchInterval: fetchResult =>
      refetchIfClipNotGenerated(fetchResult.state.data),
  })
  return { publicClip, error }
}

export function refetchIfClipNotGenerated(
  clip: PublicClip | undefined,
): number | false | undefined {
  if (clip === undefined || isProcessingOrStale(clip)) return 3000
  return false
}
