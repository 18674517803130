import {
  DeleteOutlined,
  EllipsisOutlined,
  ReloadOutlined,
} from '@ant-design/icons'
import { Button, Dropdown, Tooltip, Typography } from 'antd'
import { ItemType, SubMenuType } from 'antd/es/menu/hooks/useItems'

export function ActionMenu(props: {
  items: ItemType[] | undefined
  id: string
}) {
  return (
    <Dropdown
      menu={{ items: props.items, triggerSubMenuAction: 'click' }}
      trigger={['click']}
    >
      <Tooltip title={'More Actions'}>
        <Button
          data-testid={'actionMenuButton' + props.id}
          icon={<EllipsisOutlined rotate={90} />}
          type="text"
        />
      </Tooltip>
    </Dropdown>
  )
}

export function createImproveQualityOfClips(regenerateClips: () => void) {
  return {
    key: 'regenerate',
    icon: <ReloadOutlined />,
    label: (
      <Typography.Text data-testid="regenerateButton">
        Improve Quality of Clips
      </Typography.Text>
    ),
    onClick: regenerateClips,
  }
}

export function createDeleteDropdownItem(
  deleteAction: () => void,
): SubMenuType {
  return {
    key: 'delete',
    icon: <DeleteOutlined />,
    label: <Typography.Text data-testid="deleteButton">Delete</Typography.Text>,
    children: [
      {
        key: 'confirm',
        popupClassName: 'popup',
        danger: true,
        label: (
          <Typography.Text
            data-testid="deleteConfirmButton"
            style={{ color: 'var(--delete-text)' }}
            onClick={deleteAction}
          >
            Confirm
          </Typography.Text>
        ),
      },
    ],
  }
}
