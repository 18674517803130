import dayjs from 'dayjs'
import UTC from 'dayjs/plugin/utc'
import Timezone from 'dayjs/plugin/timezone'
import AdvancedFormat from 'dayjs/plugin/advancedFormat'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import weekday from 'dayjs/plugin/weekday'
import localeData from 'dayjs/plugin/localeData'
import relativeTime from 'dayjs/plugin/relativeTime'
import duration, { Duration } from 'dayjs/plugin/duration'
import { ClipTime } from '@skribe/web/core'

const MAX_NAME_LENGTH = 25

dayjs.extend(UTC)
dayjs.extend(Timezone)
dayjs.extend(AdvancedFormat)
dayjs.extend(LocalizedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(relativeTime)
dayjs.extend(duration)
dayjs.tz.setDefault('America/New_York')

export type SkribeDate = dayjs.Dayjs
export type SkribeDateStarter =
  | string
  | number
  | dayjs.Dayjs
  | Date
  | null
  | undefined
export type SkribeDuration = Duration

export function toLocal(time: SkribeDateStarter) {
  return dayjs.utc(time).tz(dayjs.tz.guess())
}

function toLocalFormat(time: SkribeDateStarter, format: string): string {
  return toLocal(time).format(format)
}

export const toLocalTime = (time: SkribeDateStarter) =>
  toLocalFormat(time, 'LT z')

export const toLocalDate = (time: SkribeDateStarter) =>
  toLocalFormat(time, 'LL')

export const toLocalDateTime = (time: any) => toLocalFormat(time, 'lll')

export function now() {
  return dayjs()
}
export function nowEpoch() {
  return now().valueOf()
}

export function toDate(value: SkribeDateStarter) {
  return dayjs(value)
}

function getDuration(start: number, end: number) {
  return dayjs
    .duration(end, 'seconds')
    .subtract(dayjs.duration(start, 'seconds'))
}

export function formatPlaylistDuration(clipTimes: ClipTime[]) {
  if (clipTimes.length === 0) return ''
  let durationInSeconds = 0
  for (const clipTime of clipTimes) {
    durationInSeconds += getDuration(clipTime.start, clipTime.end).asSeconds()
  }
  const duration = dayjs.duration(durationInSeconds, 'seconds')
  return formatToHrMinSec(duration)
}

function formatToHrMinSec(duration: plugin.Duration) {
  if (duration.asSeconds() < 1) return '< 1 sec'
  if (duration.asHours() > 1) return duration.format('H [hr] m [min] s [sec]')
  return duration.format('m [min] s [sec]')
}

export const formatClipDuration = (start: number, end: number) => {
  const duration = getDuration(start, end)
  return formatToHrMinSec(duration)
}
export const toDuration = (minutes: number) => dayjs.duration(minutes, 'minute')

export const toHMS = (seconds: number) =>
  dayjs.duration(seconds, 'seconds').format('H[h] m[m] s[s]')

export const addMinutesToDate = (
  date: SkribeDate,
  minutes: number,
): SkribeDate => date.add(toDuration(minutes))

export function toHourString(d: SkribeDuration): string {
  return `${d.as('hour')} hour`
}

export function formatDefaultTitle(text: string) {
  const format = 'MMDD'
  const truncatedName =
    text.substring(0, MAX_NAME_LENGTH - format.length - 1) ?? ''
  return `${truncatedName}.${toLocalFormat(now(), format)}`
}

export function isAfterYesterday(event: SkribeDateStarter) {
  const yesterday = now().subtract(1, 'day')
  return toLocal(event).isAfter(yesterday)
}
export function isBeforeDate(startDate: string | null | undefined) {
  return now().isBefore(toLocal(startDate), 'day')
}
