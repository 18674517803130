export const CLIP_QUERY_KEY = 'clip'
export const CLIPS_QUERY_KEY = 'clips'
export const EVENT_QUERY_KEY = 'event'
export const MATTERS_QUERY_KEY = 'matters'
export const PLAYLIST_QUERY_KEY = 'playlist'
export const PLAYLISTS_QUERY_KEY = 'playlists'
export const SEARCH_QUERY_KEY = 'search'
export const SETTINGS_QUERY_KEY = 'settings'
export const TRANSCRIPT_QUERY_KEY = 'transcript'
export const UPLOAD_QUERY_KEY = 'upload'
