import { LoadingComponent, MediaPlayer } from '../components'
import { useDocumentTitle } from '../utilities'
import { useClearInterval } from '../UseClearInterval'
import { useClipPageContainer } from './useClipPageContainer'
import { ClipPage } from './ClipPage'
import { useMediaPlayer } from '../PlaylistPage'

export function ClipPageContainer() {
  const { publicClip } = useClipPageContainer()
  useClearInterval()
  useDocumentTitle({ name: publicClip?.name, type: 'Clip' })

  const { playing, onPause, onProgress } = useMediaPlayer({
    selectedClip: publicClip,
  })

  if (!publicClip) return <LoadingComponent />

  return (
    <ClipPage
      publicClip={publicClip}
      breakpoints={{ xs: 20, sm: 18, md: 12, lg: 10 }}
      player={
        <MediaPlayer
          mediaUrl={publicClip?.download_url}
          reactPlayerProps={{
            onProgress,
            playing,
            onPause,
          }}
        />
      }
    />
  )
}
