import { Amplify } from 'aws-amplify'
import awsExports from './aws-exports'
import { initialize } from '@skribe/web/tracking'
import { isDevelopment } from './core'

export function onStartup() {
  const apiGatewayUrl = process.env.REACT_APP_BACKEND_ENDPOINT

  if (!apiGatewayUrl) throw Error('No backend endpoint environment variable')
  const updatedConfig = { ...awsExports }
  updatedConfig.aws_cloud_logic_custom[0].endpoint = apiGatewayUrl

  Amplify.configure(updatedConfig)
  initialize()
  removeLogsForProd()
}

function removeLogsForProd() {
  if (!isDevelopment) {
    console.log = () => {}
    console.warn = () => {}
    console.error = () => {}
  }
}
