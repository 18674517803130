import { STATUS } from './api'

export enum DownloadStatus {
  notStarted = 'notStarted',
  processing = 'processing',
  ready = 'ready',
  failed = 'failed',
  outOfDate = 'outOfDate',
}

export function statusFor(
  download_url: string | null | undefined,
  isDownloadUrlOutOfDate?: boolean,
): DownloadStatus {
  if (!download_url) return DownloadStatus.notStarted
  if (isDownloadUrlOutOfDate) return DownloadStatus.outOfDate
  if (download_url.includes(STATUS.Processing)) return DownloadStatus.processing
  if (download_url === STATUS.Failed) return DownloadStatus.failed

  return DownloadStatus.ready
}
type DownloadableItem = {
  download_url?: string | null | undefined
  isDownloadUrlOutOfDate?: boolean
}
export function areAnyProcessing(items: DownloadableItem[]) {
  return items.some(isProcessing)
}

export function isProcessingOrStale(item: DownloadableItem): boolean {
  if (item.isDownloadUrlOutOfDate) return true
  return statusFor(item.download_url, false) === DownloadStatus.processing
}

export function isProcessing(item: DownloadableItem): boolean {
  return (
    statusFor(item.download_url, !!item.isDownloadUrlOutOfDate) ===
    DownloadStatus.processing
  )
}
