import { Upload, Button, Flex } from 'antd'
import { acceptedFileTypes } from '@skribe/web/core'
import './Uploader.css'
import { InboxOutlined } from '@ant-design/icons'
import { UploaderProps, useUploader } from './useUploader'

export const Uploader: React.FC<UploaderProps> = props => {
  const { beforeUpload, onChange, iconRender, token, fileList } =
    useUploader(props)

  return (
    <Upload.Dragger
      prefixCls="skribe-uploader"
      accept={acceptedFileTypes.join(',')}
      fileList={fileList}
      beforeUpload={beforeUpload}
      multiple={props.maxCount !== 1}
      onChange={onChange}
      iconRender={iconRender}
    >
      <InboxOutlined style={{ fontSize: '5rem', color: token.colorPrimary }} />
      <Flex align="center" vertical gap="middle">
        <Button style={{ marginTop: '1em' }}>Browse</Button>
        <p>or drag a file to upload</p>
      </Flex>
      {props.children}
    </Upload.Dragger>
  )
}
