import { Clip, colors } from '@skribe/web/core'
import { Button, Col, List, Progress, Row } from 'antd'
import classnames from 'classnames'
import { HolderOutlined } from '@ant-design/icons'
import { PlayPauseButton } from '../../PlayPauseButton'
import { EditableText } from '../../index'
import { useClipSelection } from './useClipSelection'

interface BaseClipItemProps {
  clip: Clip
  playing: boolean
  onPause: () => void
  onPlay: () => void
  clipTime: JSX.Element
  selectedClipId: string | undefined
  onSelect: () => void
  editText?: (value: any) => void
  clipActionButtons?: JSX.Element
  extraLeftButton?: JSX.Element
  percentProgress?: number | undefined
}

export function BaseClipItem(props: BaseClipItemProps) {
  const { isSelectedClip, showSelectedStyle } = useClipSelection({
    clip: props.clip,
    selectedClipId: props.selectedClipId,
  })

  const showProgressBar = Boolean(
    props.percentProgress && props.percentProgress >= 0.1,
  )

  return (
    <List.Item
      className={classnames({
        draggable: true,
        'selected-clip': isSelectedClip,
      })}
      style={showSelectedStyle()}
      onClick={props.onSelect}
    >
      <div style={{ width: '100%' }}>
        <Row style={{ width: '100%' }}>
          <Col span={4} style={{ alignContent: 'center', padding: '0' }}>
            <Row wrap={false} justify={'space-around'} align={'middle'}>
              {props.extraLeftButton}
              <PlayPauseButton
                isSelected={isSelectedClip}
                playing={props.playing}
                onPause={props.onPause}
                onPlay={props.onPlay}
              />
            </Row>
          </Col>
          <Col span={20}>
            <Row justify={'space-between'} wrap={false}>
              <Col style={{ alignSelf: 'center' }}>{props.clipTime}</Col>
              {props.clipActionButtons && (
                <Col>
                  <Row>{props.clipActionButtons}</Row>
                </Col>
              )}
            </Row>
            <Row>
              <EditableText
                startingValue={props.clip.name ?? ''}
                onEdit={props.editText}
                testId="clipName"
              />
            </Row>
          </Col>
        </Row>
        {showProgressBar && (
          <Progress
            percent={props.percentProgress}
            status={'active'}
            showInfo={false}
          />
        )}
      </div>
    </List.Item>
  )
}

export const DragHandle = () => (
  <Button type="text" disabled style={{ cursor: 'grab', padding: 0 }}>
    <HolderOutlined style={{ color: colors.primary }} />
  </Button>
)
