import { useFeature } from 'flagged'
import { usePlaylistsContext } from '../../context'
import { useInvalidateData } from '../../state'
import { deleteClipsFromPlaylist } from '../ClipManager/ClipListItem/UseMarkPlaylistAsDirty'
import { trackEvent } from '@skribe/web/tracking'
import { createAddToPlaylistMenu } from '../ClipManager/ClipListItem/ClipActionButtons'
import { clipDb, playlistDb } from '@skribe/web/api'

export function useMultiSelectClip() {
  const useMultiselectFeature = useFeature('multiselect')
  const { multiSelectClips, disableMultiselect, playlists } =
    usePlaylistsContext()
  const { refreshClips, refreshPlaylists } = useInvalidateData()

  async function batchDeleteClips() {
    if (!multiSelectClips) return

    await Promise.all(multiSelectClips.map(clipDb.deleteClip))
    await Promise.all(
      playlists.map(playlist =>
        deleteClipsFromPlaylist(playlist, multiSelectClips),
      ),
    )
    trackEvent('deleteMultipleClips')
    await refreshClips()
    await refreshPlaylists()
    disableMultiselect()
  }

  async function batchAddToPlaylist(playlistId: string) {
    if (!multiSelectClips) return
    const matchingPlaylist = playlists.find(({ id }) => id === playlistId)
    if (!matchingPlaylist) return

    await playlistDb.addClipsToPlaylist(matchingPlaylist, multiSelectClips)
    trackEvent('addMultipleClipsToPlaylist')
    await refreshPlaylists()
    disableMultiselect()
  }

  const addToPlaylistItems = createAddToPlaylistMenu({
    playlists,
    addToPlaylist: id => batchAddToPlaylist(id),
  })?.children
  /** */
  const title = `${Number(multiSelectClips?.length ?? 0)} clip${
    multiSelectClips && multiSelectClips?.length > 1 ? 's' : ''
  } selected`

  function shouldHideMultiSelectBar() {
    return !(
      useMultiselectFeature &&
      multiSelectClips &&
      multiSelectClips?.length >= 1
    )
  }

  return {
    batchAddToPlaylist,
    disableMultiselect,
    batchDeleteClips,
    addToPlaylistItems,
    title,
    shouldHideMultiSelectBar,
  }
}
