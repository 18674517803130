import { useState } from 'react'
import { Col, Grid, List, Row, Typography } from 'antd'
import { truncateText } from '@skribe/web/core'
import {
  BaseClipItem,
  ClipTime,
  LoadingComponent,
} from '@skribe/web/components'
import { useDocumentTitle } from '../utilities'
import { usePlaylistPageContainer } from './usePlaylistPageContainer'
import { useMediaPlayer } from '../components/mediaPlayer/UseMediaPlayer'
import { PublicClip } from '@skribe/web/api'
import './PlaylistPageContainer.css'
import { usePlaylistPageDataSource } from './usePlaylistPageDataSource'
import { ClipPane, ClipPaneProps } from './ClipPane'

export function PlaylistPageContainer() {
  const screens = Grid.useBreakpoint()
  const [selectedClip, setSelectedClip] = useState<PublicClip | undefined>()

  const { playlist } = usePlaylistPageContainer(setSelectedClip, selectedClip)
  useDocumentTitle({ name: playlist?.name, type: 'Playlist' })
  const {
    playing,
    onPause,
    playClip,
    onPlay,
    onProgress,
    selectClip,
    onEnd,
    progressPercentage,
  } = useMediaPlayer({
    clips: playlist?.clips,
    setSelectedClip,
    selectedClip,
  })

  const { dataSource, disableNext, disablePrevious, onNext, onPrevious } =
    usePlaylistPageDataSource(
      playlist,
      selectedClip,
      progressPercentage,
      setSelectedClip,
    )

  const clipPaneProps: ClipPaneProps = {
    playlist,
    selectedClip,
    onProgress,
    playing,
    onPlay,
    onPause,
    onEnd,
    onPrevious,
    disablePrevious,
    onNext,
    disableNext,
  }
  if (!playlist) return <LoadingComponent />
  return (
    <Row id="playlist-page-container" style={{ flex: 1 }}>
      {screens.md && (
        <Col md={12} lg={6}>
          <Row style={{ paddingBottom: '1rem' }}>
            <Col>
              <Typography.Title level={4}>
                {truncateText(playlist.name)}
              </Typography.Title>
            </Col>
          </Row>
          <List
            rowKey="id"
            size="large"
            className="clip-list"
            dataSource={dataSource}
            renderItem={clip => (
              <BaseClipItem
                clip={clip}
                selectedClipId={selectedClip?.id}
                playing={playing}
                onPause={onPause}
                onSelect={() => selectClip(clip)}
                onPlay={() => playClip(clip)}
                clipTime={<ClipTime clip={clip} />}
                percentProgress={clip?.progress}
              />
            )}
          />
        </Col>
      )}
      <Col
        offset={screens.md ? 1 : undefined}
        style={{ flex: 1 }}
        className="clip-page"
        xs={20}
        md={10}
      >
        <ClipPane {...clipPaneProps} />
      </Col>
    </Row>
  )
}
