import { createContext, ReactNode, useState } from 'react'
import { Playlist } from '@skribe/web/core'

export const PlaylistsContext = createContext<
  | {
      playlists: Playlist[]
      setPlaylists: (p: Playlist[]) => void
      multiSelectClips: string[] | undefined
      setMultiSelectClips: (clip: string[] | undefined) => void
    }
  | undefined
>(undefined)

export const PlaylistsContextProvider: React.FC<{
  children: ReactNode
}> = ({ children }) => {
  const [playlists, setPlaylists] = useState<Playlist[]>([])
  const [multiSelectClips, setMultiSelectClips] = useState<
    string[] | undefined
  >()

  return (
    <PlaylistsContext.Provider
      value={{ playlists, setPlaylists, multiSelectClips, setMultiSelectClips }}
    >
      {children}
    </PlaylistsContext.Provider>
  )
}
