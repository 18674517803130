import { Clip, Playlist } from '@skribe/web/core'
import { DownloadItem } from './DownloadItem'
import { Button, Tooltip, Typography } from 'antd'
import { EditOutlined, LinkOutlined } from '@ant-design/icons'
import {
  ActionMenu,
  createAddToCategoryDropdownItem,
  createDeleteDropdownItem,
} from '../../../components'
import { trackEvent } from '@skribe/web/tracking'
import { RiPlayListAddLine } from '@remixicon/react'

function createEditItem(onEditMode: () => void) {
  return {
    key: 'edit',
    label: (
      <Typography.Text data-testid="editModeSelected">Edit</Typography.Text>
    ),
    icon: <EditOutlined />,
    onClick: onEditMode,
  }
}

export function filterOutPlaylistsWithThisClip(
  playlists: Playlist[],
  currentClipId: string,
) {
  return playlists.filter(
    p => !p.clipIds!.some(clipId => clipId === currentClipId),
  )
}

export function createAddToPlaylistMenu({
  addToPlaylist,
  clipId,
  playlists,
}: {
  playlists: Playlist[]
  clipId?: string
  addToPlaylist: (groupId: string) => Promise<void>
}) {
  const filteredPlaylists = filterOutPlaylistsWithThisClip(
    playlists!,
    clipId ?? '',
  )

  return createAddToCategoryDropdownItem({
    text: 'Save to playlist',
    icon: <RiPlayListAddLine size={'1.1em'} />,
    categories: filteredPlaylists.map(pl => ({
      ...pl,
      name: pl.name ?? `Playlist ${pl.id} `,
    })),
    disabled: filteredPlaylists.length === 0,
    onSelect: addToPlaylist,
  })
}

export function ClipActionButtons(props: {
  clip: Clip
  requestDownload: () => Promise<void>
  onCopy: () => void
  editModeSelected: boolean
  openEditMode: () => void
  onDelete: () => void
  playlists?: Playlist[]
  addToPlaylist: (playlistId: string) => Promise<void>
}) {
  const shouldShowEditItem = !props.editModeSelected

  const hasPlaylist = props.playlists && props.playlists.length > 0
  return (
    <>
      <DownloadItem
        item={props.clip}
        requestDownload={props.requestDownload}
        trackDownload={() => trackEvent('downloadClip', { clip: props.clip })}
        itemName={'Clip'}
      />
      <Tooltip title="Copy share link to clipboard">
        <Button onClick={props.onCopy} icon={<LinkOutlined />} type="text" />
      </Tooltip>
      <ActionMenu
        id="transcriptActionMenu"
        items={
          [
            shouldShowEditItem && createEditItem(props.openEditMode),
            hasPlaylist &&
              createAddToPlaylistMenu({
                addToPlaylist: props.addToPlaylist,
                clipId: props.clip.id,
                playlists: props?.playlists ?? [],
              }),
            createDeleteDropdownItem(props.onDelete),
          ].filter(Boolean) as any[]
        }
      />
    </>
  )
}
