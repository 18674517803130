import { ChatClip, ClipTime } from '@skribe/web/core'
import { Button, Col, Row, Tooltip } from 'antd'
import { timestamp } from '../../../utilities/timeUtilities'
import { trackEvent } from '@skribe/web/tracking'
import { VideoCameraAddOutlined } from '@ant-design/icons'
import { useTranscriptPageContext } from '../../../context/TranscriptPageContext'
import { useTranscriptPlayerContext } from '../../../context'
import { PlayPauseButton } from '@skribe/web/components'
import { useSelectAiClip } from './useSelectAiClip'

function AiClip(props: {
  index: number
  clip: ChatClip
  transcriptId: string
  clipSelected: boolean
  selectClip: () => void
}) {
  const { createClip, playClipTime, playing, stoppedPlaying } =
    useTranscriptPlayerContext()

  function createClipAndTrack(
    start: number,
    end: number,
    name: string,
    transcriptId: string,
  ) {
    trackEvent('createClip', {
      parent: transcriptId,
      start,
      end,
      name,
      type: 'ai',
    })
    return createClip(start, end, name, transcriptId)
  }

  function onPlay(clipTime: ClipTime) {
    return () => {
      trackEvent('clickAIClip', clipTime)
      props.selectClip()
      playClipTime(clipTime)
    }
  }

  return (
    <li id={props.index.toString()}>
      <Row justify={'space-between'}>
        <Col span={20}>
          <ClipName clip={props.clip} playClip={onPlay(props.clip)} />
        </Col>
        <Col>
          <Row>
            <PlayPauseButton
              onPlay={onPlay(props.clip)}
              onPause={stoppedPlaying}
              isSelected={props.clipSelected}
              playing={playing}
            />
            <CreateClipButton
              createClip={createClipAndTrack}
              clip={props.clip}
              transcriptId={props.transcriptId}
            />
          </Row>
        </Col>
      </Row>
    </li>
  )
}

export function RelevantClips(props: { clips: ChatClip[] }) {
  const { transcriptId } = useTranscriptPageContext()
  const { isClipSelected, selectClip } = useSelectAiClip()

  if (props.clips.length === 0 || !transcriptId) return <></>

  return (
    <>
      <h4>Relevant Clips:</h4>
      <ul>
        {props.clips.map((clip, index) => {
          return (
            <AiClip
              key={index}
              index={index}
              clip={clip}
              transcriptId={transcriptId}
              clipSelected={isClipSelected(index)}
              selectClip={selectClip(index)}
            />
          )
        })}
      </ul>
    </>
  )
}

function ClipName(props: {
  clip: ClipTime & {
    title: string
  }
  playClip: () => void
}) {
  return (
    <Tooltip title={'Watch now @' + timestamp(props.clip.start)}>
      <Button className="chat-clip-link" onClick={props.playClip}>
        {props.clip.title}
      </Button>
    </Tooltip>
  )
}

function CreateClipButton(props: {
  createClip: (
    start: number,
    end: number,
    name: string,
    transcriptId: string,
  ) => Promise<void>
  clip: ClipTime & {
    title: string
  }
  transcriptId: string
}) {
  const { createClip, clip, transcriptId } = props
  return (
    <Tooltip title="Save this clip">
      <Button
        shape="circle"
        type="text"
        icon={<VideoCameraAddOutlined />}
        onClick={() => {
          createClip(clip.start, clip.end, clip.title, transcriptId)
        }}
      />
    </Tooltip>
  )
}
