import { Button, Col, List, Row, Tooltip, Typography } from 'antd'
import { FolderAddOutlined } from '@ant-design/icons'
import { trackEvent } from '@skribe/web/tracking'

export function CreatePlaylistItem(props: {
  onCreate: (name: string) => void
}) {
  function createPlaylist() {
    const name = 'Untitled Playlist'
    trackEvent('createPlaylist', { name })
    props.onCreate(name)
  }
  return (
    <List.Item>
      <Row style={{ width: '100%' }}>
        <Col
          span={2}
          style={{ display: 'flex', justifyContent: 'space-around' }}
        >
          <Tooltip title="Create new Playlist">
            <Button
              onClick={createPlaylist}
              icon={<FolderAddOutlined />}
              type="primary"
              shape="circle"
            />
          </Tooltip>
        </Col>
        <Col span={21} offset={1}>
          <Typography.Title level={5}> Create new Playlist </Typography.Title>
        </Col>
      </Row>
    </List.Item>
  )
}
