import { ClipItemProps, useClipItem } from './UseClipItem'
import { EditableClipTime } from '../../../components/clip/EditableClip/ClipTime'
import { ClipActionButtons } from './ClipActionButtons'
import { BaseClipItem, DragHandle } from '@skribe/web/components'
import { usePlaylistsContext } from 'src/app/context'
import { Button } from 'antd'
import { RiCheckboxBlankLine, RiCheckboxFill } from '@remixicon/react'
import { colors } from 'src/core/Colors'
import { useFeature } from 'flagged'

export function ClipItem(props: ClipItemProps) {
  const useMultiSelect = useFeature('multiselect')
  const { isMultiSelectEnabled } = usePlaylistsContext()
  const {
    editModeSelected,
    openEditMode,
    editClipLength,
    closeEditMenu,
    addToPlaylist,
    requestDownload,
  } = useClipItem(props)
  return (
    <BaseClipItem
      extraLeftButton={
        useMultiSelect && isMultiSelectEnabled ? (
          <Checkbox clipId={props.clip.id} />
        ) : (
          <DragHandle />
        )
      }
      selectedClipId={props.selectedClipId}
      onSelect={() => props.selectClip(props.clip)}
      clip={props.clip}
      playing={props.isPlaying}
      onPause={props.onPause}
      onPlay={props.onPlay}
      editText={props.editTitle}
      clipTime={
        <EditableClipTime
          clip={props.clip}
          max={props.transcriptDuration}
          editModeSelected={editModeSelected}
          onCancel={closeEditMenu}
          onConfirm={editClipLength}
        />
      }
      clipActionButtons={
        <ClipActionButtons
          clip={props.clip}
          onCopy={props.onCopy}
          onDelete={props.onDelete}
          playlists={props.playlists}
          requestDownload={requestDownload}
          editModeSelected={editModeSelected}
          openEditMode={openEditMode}
          addToPlaylist={addToPlaylist}
        />
      }
    />
  )
}

const Checkbox: React.FC<{ clipId: string }> = ({ clipId }) => {
  const { toggleClipSelection, isClipSelected } = usePlaylistsContext()
  const icon = isClipSelected(clipId) ? (
    <RiCheckboxFill color={colors.primary} />
  ) : (
    <RiCheckboxBlankLine color={colors.primary} />
  )
  return (
    <Button
      type="text"
      icon={icon}
      onClick={() => toggleClipSelection(clipId)}
    />
  )
}
