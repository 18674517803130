import React from 'react'
import { Button, Slider, Tooltip } from 'antd'
import { VideoCameraAddOutlined } from '@ant-design/icons'
import { timestamp } from '../../utilities/timeUtilities'
import './ManualClipCreator.css'

import {
  ClipEndInput,
  ClipStartInput,
  useClipControls,
} from '@skribe/web/components'
import { getDefaultClipRangeEnd } from './getDefaultClipRangeEnd'

interface ManualClipCreatorProps {
  createClip: (start: number, end: number, name: string) => void
  duration: number
}

export const OldManualClipCreator: React.FC<ManualClipCreatorProps> = props => {
  const duration = Math.floor(props.duration)
  const initialRange = [0, getDefaultClipRangeEnd(duration)]

  const {
    range,
    setRange,
    step,
    startMax,
    onStartInputChange,
    onEndInputChange,
  } = useClipControls({ initialRange, duration })

  function createClip() {
    props.createClip(range[0], range[1], 'Manual Clip')
  }

  return (
    <div
      style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}
    >
      <Slider
        range
        min={0}
        className="manual-clip-creator-slider"
        step={step}
        data-testid="slider"
        max={duration}
        onChange={setRange}
        value={range}
        tooltip={{ formatter: timestamp }}
      />
      <ClipStartInput
        step={step}
        range={range}
        max={startMax}
        onChange={onStartInputChange}
      />
      -
      <ClipEndInput
        step={step}
        range={range}
        max={duration}
        onChange={onEndInputChange}
      />
      <ClipCreationButton createClip={createClip} />
    </div>
  )
}

export function ClipCreationButton(props: { createClip: () => void }) {
  return (
    <Tooltip title="Create Clip">
      <Button
        type="primary"
        style={{ display: 'block' }}
        shape="circle"
        data-testid="create-clip"
        icon={<VideoCameraAddOutlined />}
        onClick={props.createClip}
      />
    </Tooltip>
  )
}
