import { ReactNode, Ref } from 'react'
import { Card, Row, Space, Typography } from 'antd'
import { Clip, GetTranscriptResponse, OnSpeakerChange } from '@skribe/web/core'
import {
  ActionMenu,
  createDeleteDropdownItem,
  createImproveQualityOfClips,
} from '../../components'
import { TAB } from '../transcriptStrings'
import { Breadcrumb } from '../../Layout'

import { useTranscriptControls } from './UseTranscriptControls'
import { SearchBar } from './SearchBar'
import { SpeakersPopover } from './SpeakersPopover'
import { PdfButtons } from './PdfButtons'
import { TranscriptTitle } from './TranscriptTitle'
import { ReloadOutlined } from '@ant-design/icons'
import { MenuItemType } from 'antd/es/menu/hooks/useItems'

export const TranscriptControls: React.FC<{
  transcriptHeaderRef: Ref<HTMLDivElement>
  back: string
  onDeleteClick: () => Promise<void>
  transcript: GetTranscriptResponse
  tab: TAB
  onRegeneratePDF: () => Promise<void>
  speakers: { name: string; index: number }[]
  onSpeakerChange: OnSpeakerChange
  onSearch: (value: string) => void
  searchPrevious: () => void
  searchNext: () => void
  hasUserSearched: boolean
  searchItemCount: number
  resultCount: number | undefined
  children?: ReactNode
  clips: Clip[]
}> = ({
  transcriptHeaderRef,
  back,
  onDeleteClick,
  transcript,
  tab,
  onRegeneratePDF,
  speakers,
  onSpeakerChange,
  onSearch,
  searchPrevious,
  searchNext,
  searchItemCount,
  resultCount,
  hasUserSearched,
  clips,
  children,
}) => {
  const { value, setValue, regenerateClips } = useTranscriptControls({
    clips,
    tab,
  })

  return (
    <Card
      ref={transcriptHeaderRef}
      data-testid="transcript-controls"
      className="transcript-controls"
    >
      <Row justify="space-between" wrap={false}>
        <Breadcrumb transcriptId={transcript.id} back={back} />
        <ActionMenu
          id="transcriptActionMenu"
          items={
            [
              createDeleteDropdownItem(onDeleteClick),
              createImproveQualityOfClips(regenerateClips),
              !transcript.pdf_url && {
                key: 'regeneratePdf',
                icon: <ReloadOutlined />,
                label: (
                  <Typography.Text data-testid="regeneratePdf">
                    Regenerate Pdf
                  </Typography.Text>
                ),
                onClick: onRegeneratePDF,
              },
            ].filter(Boolean) as MenuItemType[]
          }
        />
      </Row>
      <TranscriptTitle transcript={transcript} />
      <Space size={8} wrap style={{ paddingBottom: 16 }}>
        <PdfButtons transcript={transcript} onRegeneratePDF={onRegeneratePDF} />
        <SpeakersPopover
          speakers={speakers}
          onSpeakerChange={onSpeakerChange}
          children={children}
        />
      </Space>
      <SearchBar
        value={value}
        onSearch={onSearch}
        setValue={setValue}
        hasUserSearched={hasUserSearched}
        searchPrevious={searchPrevious}
        searchNext={searchNext}
        searchItemCount={searchItemCount}
        resultCount={resultCount}
      />
    </Card>
  )
}
