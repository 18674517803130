import { OnProgressProps } from 'react-player/base'
import { PublicClip, PublicPlaylist } from '@skribe/web/api'
import { Button, Result, Row } from 'antd'
import { LoadingComponent, MediaPlayer } from '@skribe/web/components'
import { ClipPage } from '../ClipPage'

export type ClipPaneProps = {
  onProgress: (state: OnProgressProps) => void
  playing: boolean
  onPlay: () => void
  onPause: () => void
  onEnd: () => void
  onPrevious: () => void
  disablePrevious: boolean
  onNext: () => void
  disableNext: undefined | false | true
  selectedClip: PublicClip | undefined
  playlist: PublicPlaylist | undefined
}
export const ClipPane: React.FC<ClipPaneProps> = ({
  onProgress,
  playing,
  onPause,
  onPlay,
  onEnd,
  ...props
}) => {
  if (props.playlist?.clipIds?.length === 0)
    return <Result status="warning" title="This playlist has no clips" />
  if (!props.selectedClip) return <LoadingComponent />

  return (
    <ClipPage
      breakpoints={{}}
      publicClip={props.selectedClip}
      player={
        <MediaPlayer
          mediaUrl={props.selectedClip?.download_url}
          reactPlayerProps={{
            onProgress,
            playing,
            onPlay,
            onPause,
            onEnded: onEnd,
          }}
        />
      }
      navigationButtons={
        <Row>
          <Button
            type="text"
            onClick={props.onPrevious}
            disabled={props.disablePrevious}
          >
            Previous
          </Button>
          <Button
            type="text"
            onClick={props.onNext}
            disabled={props.disableNext}
          >
            Next
          </Button>
        </Row>
      }
    />
  )
}
