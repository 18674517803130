export const colors = {
  primary: '#02178c',
  hyperlink: '#3655FF',
  disabled: '#ADB5BD',
  grey: '#f8f8f8',
  delete: '#FFDEDE',
  error: '#FF4D47',
  activeBackground: '#E9ECEF',
  infoBackground: '#F8F9FF',
  white: '#FFF',
  processing: '#6C757D',
  green: '#52c41a',
}
