import {
  Form,
  Typography,
  Row,
  Col,
  Select,
  Button,
  Card,
  Switch,
  Divider,
} from 'antd'
import React from 'react'
import { useDocumentTitle } from '../utilities'
import { useAuth, useAuthActions, useTenantsState } from '@frontegg/react'
import { useVendorUser } from '../Auth/useVendorUser'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'
import { Settings } from '@skribe/web/core'
import { fetchSettings, postSettings } from 'src/edge/api/Amplify/settings'
import { SETTINGS_QUERY_KEY } from '../state'

export function SettingsPage() {
  useDocumentTitle({ name: 'Settings' })
  return (
    <Card style={{ maxWidth: '75em', alignSelf: 'center' }}>
      <SelectFronteggGroup />
      <Divider />
      <AdvancedGroupSettings />
      <Divider />
      <AllowStaffAccess />
    </Card>
  )
}

function AllowStaffAccess() {
  const [form] = Form.useForm<Settings>()
  const { setFieldsValue } = form
  const queryClient = useQueryClient()
  const { data: settings, isLoading } = useQuery({
    queryKey: [SETTINGS_QUERY_KEY],
    queryFn: fetchSettings,
  })
  const mutation = useMutation({
    mutationFn: postSettings,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [SETTINGS_QUERY_KEY] })
    },
  })
  const onChange = async (settings: Settings) => {
    try {
      mutation.mutate(settings)
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    if (!settings) return
    setFieldsValue(settings)
  }, [settings, setFieldsValue])
  return (
    <Form
      colon={false}
      form={form}
      onValuesChange={onChange}
      layout="horizontal"
    >
      <Row align={'middle'} justify={'space-between'}>
        <Col>
          <Row>
            <Typography.Title level={5}>
              Allow Skribe Support Access
            </Typography.Title>
          </Row>
          <Row>
            <Typography.Text>
              When enabled, Skribe Support will be added to this firm to assist
              in scheduling and uploading content.
            </Typography.Text>
          </Row>
        </Col>
        <Col>
          <Form.Item name="supportAccess">
            <Switch loading={isLoading} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

function AdvancedGroupSettings() {
  const { openSettings } = useVendorUser()

  return (
    <Row justify="space-between" align="middle">
      <Col>
        <Row>
          <Typography.Title level={5}>Advanced Group Settings</Typography.Title>
        </Row>
      </Col>
      <Col>
        <Button type={'primary'} onClick={openSettings}>
          Go To Group Settings
        </Button>
      </Col>
    </Row>
  )
}

function SelectFronteggGroup() {
  const { user } = useAuth()
  const { switchTenant } = useAuthActions()
  const { tenants } = useTenantsState()

  const handleChange = (tenantId: string) => {
    switchTenant({ tenantId })
  }

  return (
    <>
      <Row align={'middle'}>
        <Col span={7}>
          <Row>
            <Typography.Title level={5}>Select Group</Typography.Title>
          </Row>
        </Col>
        <Col span={4}>
          <Select
            defaultValue={user?.tenantId}
            style={{ width: 400 }}
            onChange={handleChange}
            options={tenants.map(tenant => {
              return { value: tenant.tenantId, label: tenant.name }
            })}
          />
        </Col>
      </Row>
    </>
  )
}
