import { Button, Card, Col, Dropdown, Row, Tooltip } from 'antd'
import { colors } from '@skribe/web/core'
import { RiCloseLine, RiPlayListAddLine } from '@remixicon/react'
import { DeleteOutlined } from '@ant-design/icons'
import { createDeleteDropdownItem } from '@skribe/web/components'
import { useMultiSelectClip } from './useMultiSelectClip'

export const MultiSelectClip = () => {
  const {
    disableMultiselect,
    batchDeleteClips,
    addToPlaylistItems,
    title,
    shouldHideMultiSelectBar,
  } = useMultiSelectClip()

  if (shouldHideMultiSelectBar()) return null
  return (
    <Card
      size="small"
      bodyStyle={{ borderRadius: '1rem', backgroundColor: colors.primary }}
      style={{
        borderRadius: '1rem',
        color: 'white',
        backgroundColor: colors.primary,
      }}
    >
      <Row align={'middle'} justify={'space-between'}>
        <Col span={2}>
          <Tooltip title="Close Multiselect mode">
            <Button
              type="text"
              icon={<RiCloseLine style={{ color: colors.white }} />}
              onClick={disableMultiselect}
            />
          </Tooltip>
        </Col>
        <Col span={18}>{title}</Col>
        <Col span={4}>
          <Row justify="space-around">
            <Dropdown
              menu={{
                items: addToPlaylistItems,
                triggerSubMenuAction: 'click',
              }}
              trigger={['click']}
            >
              <Tooltip title="Save to playlist">
                <Button
                  type="text"
                  icon={<RiPlayListAddLine style={{ color: colors.white }} />}
                />
              </Tooltip>
            </Dropdown>
            <Dropdown
              menu={{
                items: createDeleteDropdownItem(batchDeleteClips).children,
                triggerSubMenuAction: 'click',
              }}
              trigger={['click']}
            >
              <Tooltip title="Delete">
                <Button
                  type="text"
                  icon={<DeleteOutlined style={{ color: colors.white }} />}
                />
              </Tooltip>
            </Dropdown>
          </Row>
        </Col>
      </Row>
    </Card>
  )
}
